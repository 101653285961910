import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Button, TextField } from "@mui/material";

const ConfirmRegistration = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // As explained in the Login page.
    const onSubmit = async () => {
        console.log("IMPLEMENT!");
    };

    return <form style={{ display: "flex", flexDirection: "column", maxWidth: "300px", margin: "auto" }}>
        <h1>Confirm registration...</h1>
        
        <Button variant="contained" color="primary" onClick={onSubmit}>
            Confirm!
        </Button>
    </form>
}

export default ConfirmRegistration;