import React from 'react';

type Props = {};

const XmlPage = (props: Props) => {
  return (
    <>
    <h1>XML Page</h1>
    </>
    
  );
};

export default XmlPage;