import * as React from 'react';
import { useContext } from 'react';
import { Outlet, useOutletContext } from "react-router-dom";
import { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { Box, Toolbar } from "@mui/material";

import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Footer from '../../pages/home/Footer';
import getLPTheme from '../../pages/home/getLPTheme';

import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';

import * as ApiComm from "../../comm/ApiComm";

type ContextType = {
  mode: PaletteMode,
  toggleColorMode: () => void,
  notifyMessage: (message: string, type: number) => void
};

interface State extends SnackbarOrigin {
  open: boolean;
}

const MainLayout = () => {
  const [message, setMessage] = React.useState<string | undefined>("");
  const [mode, setMode] = React.useState<PaletteMode>('light');
  const defaultTheme = createTheme({ palette: { mode } });
  const LPtheme = createTheme(getLPTheme(mode));

  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'bottom',
    horizontal: 'left',
  });
  const { vertical, horizontal, open } = state;
  const [severity, setSeverity] = React.useState<AlertColor>('info');

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const handleCloseNotification = () => {
    setState({ ...state, open: false });
  };

  const notifyMessage = (message: string, type: number) => {
    setState({ ...state, open: true });
    setMessage(message);

    switch(type) {
      case ApiComm.CALLBACK_CODE_INFO:
        setSeverity("info");
        break;
        case ApiComm.CALLBACK_CODE_SUCCESS:
        setSeverity("success");
        break;
        case ApiComm.CALLBACK_CODE_WARNING:
        setSeverity("warning");
        break;
        case ApiComm.CALLBACK_CODE_ERROR:
        setSeverity("error");
        break;
    }
  };

  return (

    <>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Box sx={{ display: "flex" }}>
          <Sidebar mode={mode} toggleColorMode={toggleColorMode} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 0,
              width: `calc(100% - ${sizeConfigs.sidebar.width})`,
              minHeight: `calc("100vh" - ${sizeConfigs.topbar.height})`,
              backgroundColor: 'background.default'
            }}
          >
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
              }}
            >
              <Outlet context={{ mode, toggleColorMode, notifyMessage } satisfies ContextType} />
            </Box>
            <Box sx={{ bgcolor: 'background.default' }}>
              <Divider />
              <Footer />
            </Box>
          </Box>

          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            autoHideDuration={5000}
            onClose={handleCloseNotification}            
            key={vertical + horizontal}
          >
            <Alert
              onClose={handleCloseNotification}
              severity={severity}
              variant="filled"
              sx={{ width: '100%' }}
            >{message}</Alert>
          </Snackbar>
        </Box>
      </ThemeProvider>
    </>
  );
};

export function useMode() {
  return useOutletContext<ContextType>();
}

export default MainLayout;