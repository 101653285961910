import React, { useContext } from 'react';

import { Box, Button, Divider, Paper, Stack, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { postBinaryData } from "../../comm/ApiComm";
import { BinaryData } from '../../models/BinaryData';
import { ApiAnswer } from '../../models/ApiAnswer';
import { UserContext } from '../../contexts/UserContext';
import { useMode } from '../../components/layout/MainLayout';
import Clip from '../../components/Clip';

type Props = {};

const BinaryPage = (props: Props) => {
  const [binaryData, setBinaryData] = React.useState<ApiAnswer<BinaryData>>();
  const user = useContext(UserContext);
  const { notifyMessage } = useMode();

  React.useEffect(() => {
    if (binaryData) {
      console.log(binaryData);
    }
  }, [binaryData]);

  const getTable = (name: string, data: any[] | undefined) => {
    if (!data) {
      return <></>
    }

    var rows = [];

    var perRow = 5;
    for (var i = 0; i < data?.length; i += perRow) {
      var row = [];
      for (var j = 0; j < perRow; j++) {
        row.push(<TableCell>{data[i + j]}</TableCell>);
      }
      rows.push(row);
    }

    const handleCellOver = () => {
      
    }

    return <>
      <Stack direction={"column"}>
        <h6>{name}</h6>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 200 }} aria-label="simple table">
            <TableBody>
              {
                rows.map((row, i) => {
                  return <TableRow key={"row_" + i}>
                    {
                      row.map((e, j) => {
                        return <TableCell id={"cell_" + name.toLocaleLowerCase() + "_" + (i * perRow + j)} onMouseEnter={handleCellOver}>{e}</TableCell>
                      })
                    }
                  </TableRow>
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  }

  return (
    <>
      <h1>Binary Utilities</h1>
      <Divider />
      <p>
        Analyses the input and shows in various representations.
      </p>

      <div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            const obj = Object.fromEntries(new FormData(event.currentTarget));
            postBinaryData(user, obj, setBinaryData, notifyMessage);
          }}
        >
          <div>
            <Box component={Paper} >
              <TextField
                placeholder="Insert your text..."
                multiline
                rows={4}
                sx={{ width: "100%" }}
                name="input_string"
              />
            </Box>
          </div>
          <Button type="submit" variant="contained">Analyze</Button>
        </form>
      </div>

      <Stack spacing={2} direction={"row"}>
        {
          binaryData ?
            <>
              {getTable("Symbol", binaryData.data?.symbol)}
              {getTable("Dec", binaryData.data?.dec)}
              {getTable("Hex", binaryData.data?.hex)}
              {getTable("Binary", binaryData.data?.binary)}
            </> : <></>
        }
      </Stack>
    </>

  );
};

export default BinaryPage;