// CustomBarChart.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';

interface CustomBarChartProps {
    labels: string[];
    data: number[];
    label: string;
}

const CustomBarChart = ({ labels, data, label }: CustomBarChartProps) => {

    return (
        <BarChart
            series={[
                { data: data }
//                { data: [51, 6, 49, 30] },  
            ]}            
            height={290}
            xAxis={[{ data: labels, scaleType: 'band' }]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
        />
    );
};

export default CustomBarChart;