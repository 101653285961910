import Clip from '../../../components/Clip';

export const getASCII = () => {
  return <table>
    <tbody><tr>
      <th><b>DEC</b></th>
      <th><b>OCT</b></th>
      <th><b>HEX</b></th>
      <th><b>BIN</b></th>
      <th><b>Symbol</b></th>
      <th><b>HTML Code</b></th>
      <th><b>HTML Entity</b></th>
      <th><b>Name</b></th>
    </tr>
      <tr><td><Clip value={"0"} /></td><td><Clip value={"000"} /></td><td><Clip value={"00"} /></td><td><Clip value={"00000000"} /></td><td><Clip value={"NUL"} /></td><td><Clip value={"&#000;"} /></td><td></td><td><Clip value={"Null char"} /></td></tr>
      <tr><td><Clip value={"1"} /></td><td><Clip value={"001"} /></td><td><Clip value={"01"} /></td><td><Clip value={"00000001"} /></td><td><Clip value={"SOH"} /></td><td><Clip value={"&#001;"} /></td><td></td><td><Clip value={"Start of Heading"} /></td></tr>
      <tr><td><Clip value={"2"} /></td><td><Clip value={"002"} /></td><td><Clip value={"02"} /></td><td><Clip value={"00000010"} /></td><td><Clip value={"STX"} /></td><td><Clip value={"&#002;"} /></td><td></td><td><Clip value={"Start of Text"} /></td></tr>
      <tr><td><Clip value={"3"} /></td><td><Clip value={"003"} /></td><td><Clip value={"03"} /></td><td><Clip value={"00000011"} /></td><td><Clip value={"ETX"} /></td><td><Clip value={"&#003;"} /></td><td></td><td><Clip value={"End of Text"} /></td></tr>
      <tr><td><Clip value={"4"} /></td><td><Clip value={"004"} /></td><td><Clip value={"04"} /></td><td><Clip value={"00000100"} /></td><td><Clip value={"EOT"} /></td><td><Clip value={"&#004;"} /></td><td></td><td><Clip value={"End of Transmission"} /></td></tr>
      <tr><td><Clip value={"5"} /></td><td><Clip value={"005"} /></td><td><Clip value={"05"} /></td><td><Clip value={"00000101"} /></td><td><Clip value={"ENQ"} /></td><td><Clip value={"&#005;"} /></td><td></td><td><Clip value={"Enquiry"} /></td></tr>
      <tr><td><Clip value={"6"} /></td><td><Clip value={"006"} /></td><td><Clip value={"06"} /></td><td><Clip value={"00000110"} /></td><td><Clip value={"ACK"} /></td><td><Clip value={"&#006;"} /></td><td></td><td><Clip value={"Acknowledgment"} /></td></tr>
      <tr><td><Clip value={"7"} /></td><td><Clip value={"007"} /></td><td><Clip value={"07"} /></td><td><Clip value={"00000111"} /></td><td><Clip value={"BEL"} /></td><td><Clip value={"&#007;"} /></td><td></td><td><Clip value={"Bell"} /></td></tr>
      <tr><td><Clip value={"8"} /></td><td><Clip value={"010"} /></td><td><Clip value={"08"} /></td><td><Clip value={"00001000"} /></td><td><Clip value={" BS"} /></td><td><Clip value={"&#008;"} /></td><td></td><td><Clip value={"Back Space"} /></td></tr>
      <tr><td><Clip value={"9"} /></td><td><Clip value={"011"} /></td><td><Clip value={"09"} /></td><td><Clip value={"00001001"} /></td><td><Clip value={" HT"} /></td><td><Clip value={"&#009;"} /></td><td></td><td><Clip value={"Horizontal Tab"} /></td></tr>
      <tr><td><Clip value={"10"} /></td><td><Clip value={"012"} /></td><td><Clip value={"0A"} /></td><td><Clip value={"00001010"} /></td><td><Clip value={" LF"} /></td><td><Clip value={"&#010;"} /></td><td></td><td><Clip value={"Line Feed"} /></td></tr>
      <tr><td><Clip value={"11"} /></td><td><Clip value={"013"} /></td><td><Clip value={"0B"} /></td><td><Clip value={"00001011"} /></td><td><Clip value={" VT"} /></td><td><Clip value={"&#011;"} /></td><td></td><td><Clip value={"Vertical Tab"} /></td></tr>
      <tr><td><Clip value={"12"} /></td><td><Clip value={"014"} /></td><td><Clip value={"0C"} /></td><td><Clip value={"00001100"} /></td><td><Clip value={" FF"} /></td><td><Clip value={"&#012;"} /></td><td></td><td><Clip value={"Form Feed"} /></td></tr>
      <tr><td><Clip value={"13"} /></td><td><Clip value={"015"} /></td><td><Clip value={"0D"} /></td><td><Clip value={"00001101"} /></td><td><Clip value={" CR"} /></td><td><Clip value={"&#013;"} /></td><td></td><td><Clip value={"Carriage Return"} /></td></tr>
      <tr><td><Clip value={"14"} /></td><td><Clip value={"016"} /></td><td><Clip value={"0E"} /></td><td><Clip value={"00001110"} /></td><td><Clip value={" SO"} /></td><td><Clip value={"&#014;"} /></td><td></td><td><Clip value={"Shift Out / X-On"} /></td></tr>
      <tr><td><Clip value={"15"} /></td><td><Clip value={"017"} /></td><td><Clip value={"0F"} /></td><td><Clip value={"00001111"} /></td><td><Clip value={" SI"} /></td><td><Clip value={"&#015;"} /></td><td></td><td><Clip value={"Shift In / X-Off"} /></td></tr>
      <tr><td><Clip value={"16"} /></td><td><Clip value={"020"} /></td><td><Clip value={"10"} /></td><td><Clip value={"00010000"} /></td><td><Clip value={"DLE"} /></td><td><Clip value={"&#016;"} /></td><td></td><td><Clip value={"Data Line Escape"} /></td></tr>
      <tr><td><Clip value={"17"} /></td><td><Clip value={"021"} /></td><td><Clip value={"11"} /></td><td><Clip value={"00010001"} /></td><td><Clip value={"DC1"} /></td><td><Clip value={"&#017;"} /></td><td></td><td><Clip value={"Device Control 1 (oft. XON)"} /></td></tr>
      <tr><td><Clip value={"18"} /></td><td><Clip value={"022"} /></td><td><Clip value={"12"} /></td><td><Clip value={"00010010"} /></td><td><Clip value={"DC2"} /></td><td><Clip value={"&#018;"} /></td><td></td><td><Clip value={"Device Control 2"} /></td></tr>
      <tr><td><Clip value={"19"} /></td><td><Clip value={"023"} /></td><td><Clip value={"13"} /></td><td><Clip value={"00010011"} /></td><td><Clip value={"DC3"} /></td><td><Clip value={"&#019;"} /></td><td></td><td><Clip value={"Device Control 3 (oft. XOFF)"} /></td></tr>
      <tr><td><Clip value={"20"} /></td><td><Clip value={"024"} /></td><td><Clip value={"14"} /></td><td><Clip value={"00010100"} /></td><td><Clip value={"DC4"} /></td><td><Clip value={"&#020;"} /></td><td></td><td><Clip value={"Device Control 4"} /></td></tr>
      <tr><td><Clip value={"21"} /></td><td><Clip value={"025"} /></td><td><Clip value={"15"} /></td><td><Clip value={"00010101"} /></td><td><Clip value={"NAK"} /></td><td><Clip value={"&#021;"} /></td><td></td><td><Clip value={"Negative Acknowledgement"} /></td></tr>
      <tr><td><Clip value={"22"} /></td><td><Clip value={"026"} /></td><td><Clip value={"16"} /></td><td><Clip value={"00010110"} /></td><td><Clip value={"SYN"} /></td><td><Clip value={"&#022;"} /></td><td></td><td><Clip value={"Synchronous Idle"} /></td></tr>
      <tr><td><Clip value={"23"} /></td><td><Clip value={"027"} /></td><td><Clip value={"17"} /></td><td><Clip value={"00010111"} /></td><td><Clip value={"ETB"} /></td><td><Clip value={"&#023;"} /></td><td></td><td><Clip value={"End of Transmit Block"} /></td></tr>
      <tr><td><Clip value={"24"} /></td><td><Clip value={"030"} /></td><td><Clip value={"18"} /></td><td><Clip value={"00011000"} /></td><td><Clip value={"CAN"} /></td><td><Clip value={"&#024;"} /></td><td></td><td><Clip value={"Cancel"} /></td></tr>
      <tr><td><Clip value={"25"} /></td><td><Clip value={"031"} /></td><td><Clip value={"19"} /></td><td><Clip value={"00011001"} /></td><td><Clip value={" EM"} /></td><td><Clip value={"&#025;"} /></td><td></td><td><Clip value={"End of Medium"} /></td></tr>
      <tr><td><Clip value={"26"} /></td><td><Clip value={"032"} /></td><td><Clip value={"1A"} /></td><td><Clip value={"00011010"} /></td><td><Clip value={"SUB"} /></td><td><Clip value={"&#026;"} /></td><td></td><td><Clip value={"Substitute"} /></td></tr>
      <tr><td><Clip value={"27"} /></td><td><Clip value={"033"} /></td><td><Clip value={"1B"} /></td><td><Clip value={"00011011"} /></td><td><Clip value={"ESC"} /></td><td><Clip value={"&#027;"} /></td><td></td><td><Clip value={"Escape"} /></td></tr>
      <tr><td><Clip value={"28"} /></td><td><Clip value={"034"} /></td><td><Clip value={"1C"} /></td><td><Clip value={"00011100"} /></td><td><Clip value={" FS"} /></td><td><Clip value={"&#028;"} /></td><td></td><td><Clip value={"File Separator"} /></td></tr>
      <tr><td><Clip value={"29"} /></td><td><Clip value={"035"} /></td><td><Clip value={"1D"} /></td><td><Clip value={"00011101"} /></td><td><Clip value={" GS"} /></td><td><Clip value={"&#029;"} /></td><td></td><td><Clip value={"Group Separator"} /></td></tr>
      <tr><td><Clip value={"30"} /></td><td><Clip value={"036"} /></td><td><Clip value={"1E"} /></td><td><Clip value={"00011110"} /></td><td><Clip value={" RS"} /></td><td><Clip value={"&#030;"} /></td><td></td><td><Clip value={"Record Separator"} /></td></tr>
      <tr><td><Clip value={"31"} /></td><td><Clip value={"037"} /></td><td><Clip value={"1F"} /></td><td><Clip value={"00011111"} /></td><td><Clip value={" US"} /></td><td><Clip value={"&#031;"} /></td><td></td><td><Clip value={"Unit Separator"} /></td></tr>
      <tr><td><Clip value={"32"} /></td><td><Clip value={"040"} /></td><td><Clip value={"20"} /></td><td><Clip value={"00100000"} /></td><td></td><td><Clip value={"&#32;"} /></td><td></td><td><Clip value={"Space"} /></td></tr>
      <tr><td><Clip value={"33"} /></td><td><Clip value={"041"} /></td><td><Clip value={"21"} /></td><td><Clip value={"00100001"} /></td><td><Clip value={"!"} /></td><td><Clip value={"&#33;"} /></td><td></td><td><Clip value={"Exclamation mark"} /></td></tr>
      <tr><td><Clip value={"34"} /></td><td><Clip value={"042"} /></td><td><Clip value={"22"} /></td><td><Clip value={"00100010"} /></td><td><Clip value={'"'} /></td><td><Clip value={"&#34;"} /></td><td><Clip value={"&quot;"} /></td><td><Clip value={"Double quotes (or speech marks)"} /></td></tr>
      <tr><td><Clip value={"35"} /></td><td><Clip value={"043"} /></td><td><Clip value={"23"} /></td><td><Clip value={"00100011"} /></td><td><Clip value={"#"} /></td><td><Clip value={"&#35;"} /></td><td></td><td><Clip value={"Number"} /></td></tr>
      <tr><td><Clip value={"36"} /></td><td><Clip value={"044"} /></td><td><Clip value={"24"} /></td><td><Clip value={"00100100"} /></td><td><Clip value={"$"} /></td><td><Clip value={"&#36;"} /></td><td></td><td><Clip value={"Dollar"} /></td></tr>
      <tr><td><Clip value={"37"} /></td><td><Clip value={"045"} /></td><td><Clip value={"25"} /></td><td><Clip value={"00100101"} /></td><td><Clip value={"%"} /></td><td><Clip value={"&#37;"} /></td><td></td><td><Clip value={"Procenttecken"} /></td></tr>
      <tr><td><Clip value={"38"} /></td><td><Clip value={"046"} /></td><td><Clip value={"26"} /></td><td><Clip value={"00100110"} /></td><td><Clip value={"&"} /></td><td><Clip value={"&#38;"} /></td><td><Clip value={"&amp;"} /></td><td><Clip value={"Ampersand"} /></td></tr>
      <tr><td><Clip value={"39"} /></td><td><Clip value={"047"} /></td><td><Clip value={"27"} /></td><td><Clip value={"00100111"} /></td><td><Clip value={"'"} /></td><td><Clip value={"&#39;"} /></td><td></td><td><Clip value={"Single quote"} /></td></tr>
      <tr><td><Clip value={"40"} /></td><td><Clip value={"050"} /></td><td><Clip value={"28"} /></td><td><Clip value={"00101000"} /></td><td><Clip value={"("} /></td><td><Clip value={"&#40;"} /></td><td></td><td><Clip value={"Open parenthesis (or open bracket)"} /></td></tr>
      <tr><td><Clip value={"41"} /></td><td><Clip value={"051"} /></td><td><Clip value={"29"} /></td><td><Clip value={"00101001"} /></td><td><Clip value={")"} /></td><td><Clip value={"&#41;"} /></td><td></td><td><Clip value={"Close parenthesis (or close bracket)"} /></td></tr>
      <tr><td><Clip value={"42"} /></td><td><Clip value={"052"} /></td><td><Clip value={"2A"} /></td><td><Clip value={"00101010"} /></td><td><Clip value={"*"} /></td><td><Clip value={"&#42;"} /></td><td></td><td><Clip value={"Asterisk"} /></td></tr>
      <tr><td><Clip value={"43"} /></td><td><Clip value={"053"} /></td><td><Clip value={"2B"} /></td><td><Clip value={"00101011"} /></td><td><Clip value={"+"} /></td><td><Clip value={"&#43;"} /></td><td></td><td><Clip value={"Plus"} /></td></tr>
      <tr><td><Clip value={"44"} /></td><td><Clip value={"054"} /></td><td><Clip value={"2C"} /></td><td><Clip value={"00101100"} /></td><td><Clip value={","} /></td><td><Clip value={"&#44;"} /></td><td></td><td><Clip value={"Comma"} /></td></tr>
      <tr><td><Clip value={"45"} /></td><td><Clip value={"055"} /></td><td><Clip value={"2D"} /></td><td><Clip value={"00101101"} /></td><td><Clip value={"-"} /></td><td><Clip value={"&#45;"} /></td><td></td><td><Clip value={"Hyphen"} /></td></tr>
      <tr><td><Clip value={"46"} /></td><td><Clip value={"056"} /></td><td><Clip value={"2E"} /></td><td><Clip value={"00101110"} /></td><td><Clip value={"."} /></td><td><Clip value={"&#46;"} /></td><td></td><td><Clip value={"Period, dot or full stop"} /></td></tr>
      <tr><td><Clip value={"47"} /></td><td><Clip value={"057"} /></td><td><Clip value={"2F"} /></td><td><Clip value={"00101111"} /></td><td><Clip value={"/"} /></td><td><Clip value={"&#47;"} /></td><td></td><td><Clip value={"Slash or divide"} /></td></tr>
      <tr><td><Clip value={"48"} /></td><td><Clip value={"060"} /></td><td><Clip value={"30"} /></td><td><Clip value={"00110000"} /></td><td><Clip value={"0"} /></td><td><Clip value={"&#48;"} /></td><td></td><td><Clip value={"Zero"} /></td></tr>
      <tr><td><Clip value={"49"} /></td><td><Clip value={"061"} /></td><td><Clip value={"31"} /></td><td><Clip value={"00110001"} /></td><td><Clip value={"1"} /></td><td><Clip value={"&#49;"} /></td><td></td><td><Clip value={"One"} /></td></tr>
      <tr><td><Clip value={"50"} /></td><td><Clip value={"062"} /></td><td><Clip value={"32"} /></td><td><Clip value={"00110010"} /></td><td><Clip value={"2"} /></td><td><Clip value={"&#50;"} /></td><td></td><td><Clip value={"Two"} /></td></tr>
      <tr><td><Clip value={"51"} /></td><td><Clip value={"063"} /></td><td><Clip value={"33"} /></td><td><Clip value={"00110011"} /></td><td><Clip value={"3"} /></td><td><Clip value={"&#51;"} /></td><td></td><td><Clip value={"Three"} /></td></tr>
      <tr><td><Clip value={"52"} /></td><td><Clip value={"064"} /></td><td><Clip value={"34"} /></td><td><Clip value={"00110100"} /></td><td><Clip value={"4"} /></td><td><Clip value={"&#52;"} /></td><td></td><td><Clip value={"Four"} /></td></tr>
      <tr><td><Clip value={"53"} /></td><td><Clip value={"065"} /></td><td><Clip value={"35"} /></td><td><Clip value={"00110101"} /></td><td><Clip value={"5"} /></td><td><Clip value={"&#53;"} /></td><td></td><td><Clip value={"Five"} /></td></tr>
      <tr><td><Clip value={"54"} /></td><td><Clip value={"066"} /></td><td><Clip value={"36"} /></td><td><Clip value={"00110110"} /></td><td><Clip value={"6"} /></td><td><Clip value={"&#54;"} /></td><td></td><td><Clip value={"Six"} /></td></tr>
      <tr><td><Clip value={"55"} /></td><td><Clip value={"067"} /></td><td><Clip value={"37"} /></td><td><Clip value={"00110111"} /></td><td><Clip value={"7"} /></td><td><Clip value={"&#55;"} /></td><td></td><td><Clip value={"Seven"} /></td></tr>
      <tr><td><Clip value={"56"} /></td><td><Clip value={"070"} /></td><td><Clip value={"38"} /></td><td><Clip value={"00111000"} /></td><td><Clip value={"8"} /></td><td><Clip value={"&#56;"} /></td><td></td><td><Clip value={"Eight"} /></td></tr>
      <tr><td><Clip value={"57"} /></td><td><Clip value={"071"} /></td><td><Clip value={"39"} /></td><td><Clip value={"00111001"} /></td><td><Clip value={"9"} /></td><td><Clip value={"&#57;"} /></td><td></td><td><Clip value={"Nine"} /></td></tr>
      <tr><td><Clip value={"58"} /></td><td><Clip value={"072"} /></td><td><Clip value={"3A"} /></td><td><Clip value={"00111010"} /></td><td><Clip value={":"} /></td><td><Clip value={"&#58;"} /></td><td></td><td><Clip value={"Colon"} /></td></tr>
      <tr><td><Clip value={"59"} /></td><td><Clip value={"073"} /></td><td><Clip value={"3B"} /></td><td><Clip value={"00111011"} /></td><td><Clip value={";"} /></td><td><Clip value={"&#59;"} /></td><td></td><td><Clip value={"Semicolon"} /></td></tr>
      <tr><td><Clip value={"60"} /></td><td><Clip value={"074"} /></td><td><Clip value={"3C"} /></td><td><Clip value={"00111100"} /></td><td><Clip value={"&lt;"} /></td><td><Clip value={"&#60;"} /></td><td><Clip value={"&lt;"} /></td><td><Clip value={"Less than (or open angled bracket)"} /></td></tr>
      <tr><td><Clip value={"61"} /></td><td><Clip value={"075"} /></td><td><Clip value={"3D"} /></td><td><Clip value={"00111101"} /></td><td><Clip value={"="} /></td><td><Clip value={"&#61;"} /></td><td></td><td><Clip value={"Equals"} /></td></tr>
      <tr><td><Clip value={"62"} /></td><td><Clip value={"076"} /></td><td><Clip value={"3E"} /></td><td><Clip value={"00111110"} /></td><td><Clip value={"&gt;"} /></td><td><Clip value={"&#62;"} /></td><td><Clip value={"&gt;"} /></td><td><Clip value={"Greater than (or close angled bracket)"} /></td></tr>
      <tr><td><Clip value={"63"} /></td><td><Clip value={"077"} /></td><td><Clip value={"3F"} /></td><td><Clip value={"00111111"} /></td><td><Clip value={"?"} /></td><td><Clip value={"&#63;"} /></td><td></td><td><Clip value={"Question mark"} /></td></tr>
      <tr><td><Clip value={"64"} /></td><td><Clip value={"100"} /></td><td><Clip value={"40"} /></td><td><Clip value={"01000000"} /></td><td><Clip value={"@"} /></td><td><Clip value={"&#64;"} /></td><td></td><td><Clip value={"At symbol"} /></td></tr>
      <tr><td><Clip value={"65"} /></td><td><Clip value={"101"} /></td><td><Clip value={"41"} /></td><td><Clip value={"01000001"} /></td><td><Clip value={"A"} /></td><td><Clip value={"&#65;"} /></td><td></td><td><Clip value={"Uppercase A"} /></td></tr>
      <tr><td><Clip value={"66"} /></td><td><Clip value={"102"} /></td><td><Clip value={"42"} /></td><td><Clip value={"01000010"} /></td><td><Clip value={"B"} /></td><td><Clip value={"&#66;"} /></td><td></td><td><Clip value={"Uppercase B"} /></td></tr>
      <tr><td><Clip value={"67"} /></td><td><Clip value={"103"} /></td><td><Clip value={"43"} /></td><td><Clip value={"01000011"} /></td><td><Clip value={"C"} /></td><td><Clip value={"&#67;"} /></td><td></td><td><Clip value={"Uppercase C"} /></td></tr>
      <tr><td><Clip value={"68"} /></td><td><Clip value={"104"} /></td><td><Clip value={"44"} /></td><td><Clip value={"01000100"} /></td><td><Clip value={"D"} /></td><td><Clip value={"&#68;"} /></td><td></td><td><Clip value={"Uppercase D"} /></td></tr>
      <tr><td><Clip value={"69"} /></td><td><Clip value={"105"} /></td><td><Clip value={"45"} /></td><td><Clip value={"01000101"} /></td><td><Clip value={"E"} /></td><td><Clip value={"&#69;"} /></td><td></td><td><Clip value={"Uppercase E"} /></td></tr>
      <tr><td><Clip value={"70"} /></td><td><Clip value={"106"} /></td><td><Clip value={"46"} /></td><td><Clip value={"01000110"} /></td><td><Clip value={"F"} /></td><td><Clip value={"&#70;"} /></td><td></td><td><Clip value={"Uppercase F"} /></td></tr>
      <tr><td><Clip value={"71"} /></td><td><Clip value={"107"} /></td><td><Clip value={"47"} /></td><td><Clip value={"01000111"} /></td><td><Clip value={"G"} /></td><td><Clip value={"&#71;"} /></td><td></td><td><Clip value={"Uppercase G"} /></td></tr>
      <tr><td><Clip value={"72"} /></td><td><Clip value={"110"} /></td><td><Clip value={"48"} /></td><td><Clip value={"01001000"} /></td><td><Clip value={"H"} /></td><td><Clip value={"&#72;"} /></td><td></td><td><Clip value={"Uppercase H"} /></td></tr>
      <tr><td><Clip value={"73"} /></td><td><Clip value={"111"} /></td><td><Clip value={"49"} /></td><td><Clip value={"01001001"} /></td><td><Clip value={"I"} /></td><td><Clip value={"&#73;"} /></td><td></td><td><Clip value={"Uppercase I"} /></td></tr>
      <tr><td><Clip value={"74"} /></td><td><Clip value={"112"} /></td><td><Clip value={"4A"} /></td><td><Clip value={"01001010"} /></td><td><Clip value={"J"} /></td><td><Clip value={"&#74;"} /></td><td></td><td><Clip value={"Uppercase J"} /></td></tr>
      <tr><td><Clip value={"75"} /></td><td><Clip value={"113"} /></td><td><Clip value={"4B"} /></td><td><Clip value={"01001011"} /></td><td><Clip value={"K"} /></td><td><Clip value={"&#75;"} /></td><td></td><td><Clip value={"Uppercase K"} /></td></tr>
      <tr><td><Clip value={"76"} /></td><td><Clip value={"114"} /></td><td><Clip value={"4C"} /></td><td><Clip value={"01001100"} /></td><td><Clip value={"L"} /></td><td><Clip value={"&#76;"} /></td><td></td><td><Clip value={"Uppercase L"} /></td></tr>
      <tr><td><Clip value={"77"} /></td><td><Clip value={"115"} /></td><td><Clip value={"4D"} /></td><td><Clip value={"01001101"} /></td><td><Clip value={"M"} /></td><td><Clip value={"&#77;"} /></td><td></td><td><Clip value={"Uppercase M"} /></td></tr>
      <tr><td><Clip value={"78"} /></td><td><Clip value={"116"} /></td><td><Clip value={"4E"} /></td><td><Clip value={"01001110"} /></td><td><Clip value={"N"} /></td><td><Clip value={"&#78;"} /></td><td></td><td><Clip value={"Uppercase N"} /></td></tr>
      <tr><td><Clip value={"79"} /></td><td><Clip value={"117"} /></td><td><Clip value={"4F"} /></td><td><Clip value={"01001111"} /></td><td><Clip value={"O"} /></td><td><Clip value={"&#79;"} /></td><td></td><td><Clip value={"Uppercase O"} /></td></tr>
      <tr><td><Clip value={"80"} /></td><td><Clip value={"120"} /></td><td><Clip value={"50"} /></td><td><Clip value={"01010000"} /></td><td><Clip value={"P"} /></td><td><Clip value={"&#80;"} /></td><td></td><td><Clip value={"Uppercase P"} /></td></tr>
      <tr><td><Clip value={"81"} /></td><td><Clip value={"121"} /></td><td><Clip value={"51"} /></td><td><Clip value={"01010001"} /></td><td><Clip value={"Q"} /></td><td><Clip value={"&#81;"} /></td><td></td><td><Clip value={"Uppercase Q"} /></td></tr>
      <tr><td><Clip value={"82"} /></td><td><Clip value={"122"} /></td><td><Clip value={"52"} /></td><td><Clip value={"01010010"} /></td><td><Clip value={"R"} /></td><td><Clip value={"&#82;"} /></td><td></td><td><Clip value={"Uppercase R"} /></td></tr>
      <tr><td><Clip value={"83"} /></td><td><Clip value={"123"} /></td><td><Clip value={"53"} /></td><td><Clip value={"01010011"} /></td><td><Clip value={"S"} /></td><td><Clip value={"&#83;"} /></td><td></td><td><Clip value={"Uppercase S"} /></td></tr>
      <tr><td><Clip value={"84"} /></td><td><Clip value={"124"} /></td><td><Clip value={"54"} /></td><td><Clip value={"01010100"} /></td><td><Clip value={"T"} /></td><td><Clip value={"&#84;"} /></td><td></td><td><Clip value={"Uppercase T"} /></td></tr>
      <tr><td><Clip value={"85"} /></td><td><Clip value={"125"} /></td><td><Clip value={"55"} /></td><td><Clip value={"01010101"} /></td><td><Clip value={"U"} /></td><td><Clip value={"&#85;"} /></td><td></td><td><Clip value={"Uppercase U"} /></td></tr>
      <tr><td><Clip value={"86"} /></td><td><Clip value={"126"} /></td><td><Clip value={"56"} /></td><td><Clip value={"01010110"} /></td><td><Clip value={"V"} /></td><td><Clip value={"&#86;"} /></td><td></td><td><Clip value={"Uppercase V"} /></td></tr>
      <tr><td><Clip value={"87"} /></td><td><Clip value={"127"} /></td><td><Clip value={"57"} /></td><td><Clip value={"01010111"} /></td><td><Clip value={"W"} /></td><td><Clip value={"&#87;"} /></td><td></td><td><Clip value={"Uppercase W"} /></td></tr>
      <tr><td><Clip value={"88"} /></td><td><Clip value={"130"} /></td><td><Clip value={"58"} /></td><td><Clip value={"01011000"} /></td><td><Clip value={"X"} /></td><td><Clip value={"&#88;"} /></td><td></td><td><Clip value={"Uppercase X"} /></td></tr>
      <tr><td><Clip value={"89"} /></td><td><Clip value={"131"} /></td><td><Clip value={"59"} /></td><td><Clip value={"01011001"} /></td><td><Clip value={"Y"} /></td><td><Clip value={"&#89;"} /></td><td></td><td><Clip value={"Uppercase Y"} /></td></tr>
      <tr><td><Clip value={"90"} /></td><td><Clip value={"132"} /></td><td><Clip value={"5A"} /></td><td><Clip value={"01011010"} /></td><td><Clip value={"Z"} /></td><td><Clip value={"&#90;"} /></td><td></td><td><Clip value={"Uppercase Z"} /></td></tr>
      <tr><td><Clip value={"91"} /></td><td><Clip value={"133"} /></td><td><Clip value={"5B"} /></td><td><Clip value={"01011011"} /></td><td><Clip value={"["} /></td><td><Clip value={"&#91;"} /></td><td></td><td><Clip value={"Opening bracket"} /></td></tr>
      <tr><td><Clip value={"92"} /></td><td><Clip value={"134"} /></td><td><Clip value={"5C"} /></td><td><Clip value={"01011100"} /></td><td><Clip value={"\\"} /></td><td><Clip value={"&#92;"} /></td><td></td><td><Clip value={"Backslash"} /></td></tr>
      <tr><td><Clip value={"93"} /></td><td><Clip value={"135"} /></td><td><Clip value={"5D"} /></td><td><Clip value={"01011101"} /></td><td><Clip value={"]"} /></td><td><Clip value={"&#93;"} /></td><td></td><td><Clip value={"Closing bracket"} /></td></tr>
      <tr><td><Clip value={"94"} /></td><td><Clip value={"136"} /></td><td><Clip value={"5E"} /></td><td><Clip value={"01011110"} /></td><td><Clip value={"^"} /></td><td><Clip value={"&#94;"} /></td><td></td><td><Clip value={"Caret - circumflex"} /></td></tr>
      <tr><td><Clip value={"95"} /></td><td><Clip value={"137"} /></td><td><Clip value={"5F"} /></td><td><Clip value={"01011111"} /></td><td><Clip value={"_"} /></td><td><Clip value={"&#95;"} /></td><td></td><td><Clip value={"Underscore"} /></td></tr>
      <tr><td><Clip value={"96"} /></td><td><Clip value={"140"} /></td><td><Clip value={"60"} /></td><td><Clip value={"01100000"} /></td><td><Clip value={"`"} /></td><td><Clip value={"&#96;"} /></td><td></td><td><Clip value={"Grave accent"} /></td></tr>
      <tr><td><Clip value={"97"} /></td><td><Clip value={"141"} /></td><td><Clip value={"61"} /></td><td><Clip value={"01100001"} /></td><td><Clip value={"a"} /></td><td><Clip value={"&#97;"} /></td><td></td><td><Clip value={"Lowercase a"} /></td></tr>
      <tr><td><Clip value={"98"} /></td><td><Clip value={"142"} /></td><td><Clip value={"62"} /></td><td><Clip value={"01100010"} /></td><td><Clip value={"b"} /></td><td><Clip value={"&#98;"} /></td><td></td><td><Clip value={"Lowercase b"} /></td></tr>
      <tr><td><Clip value={"99"} /></td><td><Clip value={"143"} /></td><td><Clip value={"63"} /></td><td><Clip value={"01100011"} /></td><td><Clip value={"c"} /></td><td><Clip value={"&#99;"} /></td><td></td><td><Clip value={"Lowercase c"} /></td></tr>
      <tr><td><Clip value={"100"} /></td><td><Clip value={"144"} /></td><td><Clip value={"64"} /></td><td><Clip value={"01100100"} /></td><td><Clip value={"d"} /></td><td><Clip value={"&#100;"} /></td><td></td><td><Clip value={"Lowercase d"} /></td></tr>
      <tr><td><Clip value={"101"} /></td><td><Clip value={"145"} /></td><td><Clip value={"65"} /></td><td><Clip value={"01100101"} /></td><td><Clip value={"e"} /></td><td><Clip value={"&#101;"} /></td><td></td><td><Clip value={"Lowercase e"} /></td></tr>
      <tr><td><Clip value={"102"} /></td><td><Clip value={"146"} /></td><td><Clip value={"66"} /></td><td><Clip value={"01100110"} /></td><td><Clip value={"f"} /></td><td><Clip value={"&#102;"} /></td><td></td><td><Clip value={"Lowercase f"} /></td></tr>
      <tr><td><Clip value={"103"} /></td><td><Clip value={"147"} /></td><td><Clip value={"67"} /></td><td><Clip value={"01100111"} /></td><td><Clip value={"g"} /></td><td><Clip value={"&#103;"} /></td><td></td><td><Clip value={"Lowercase g"} /></td></tr>
      <tr><td><Clip value={"104"} /></td><td><Clip value={"150"} /></td><td><Clip value={"68"} /></td><td><Clip value={"01101000"} /></td><td><Clip value={"h"} /></td><td><Clip value={"&#104;"} /></td><td></td><td><Clip value={"Lowercase h"} /></td></tr>
      <tr><td><Clip value={"105"} /></td><td><Clip value={"151"} /></td><td><Clip value={"69"} /></td><td><Clip value={"01101001"} /></td><td><Clip value={"i"} /></td><td><Clip value={"&#105;"} /></td><td></td><td><Clip value={"Lowercase i"} /></td></tr>
      <tr><td><Clip value={"106"} /></td><td><Clip value={"152"} /></td><td><Clip value={"6A"} /></td><td><Clip value={"01101010"} /></td><td><Clip value={"j"} /></td><td><Clip value={"&#106;"} /></td><td></td><td><Clip value={"Lowercase j"} /></td></tr>
      <tr><td><Clip value={"107"} /></td><td><Clip value={"153"} /></td><td><Clip value={"6B"} /></td><td><Clip value={"01101011"} /></td><td><Clip value={"k"} /></td><td><Clip value={"&#107;"} /></td><td></td><td><Clip value={"Lowercase k"} /></td></tr>
      <tr><td><Clip value={"108"} /></td><td><Clip value={"154"} /></td><td><Clip value={"6C"} /></td><td><Clip value={"01101100"} /></td><td><Clip value={"l"} /></td><td><Clip value={"&#108;"} /></td><td></td><td><Clip value={"Lowercase l"} /></td></tr>
      <tr><td><Clip value={"109"} /></td><td><Clip value={"155"} /></td><td><Clip value={"6D"} /></td><td><Clip value={"01101101"} /></td><td><Clip value={"m"} /></td><td><Clip value={"&#109;"} /></td><td></td><td><Clip value={"Lowercase m"} /></td></tr>
      <tr><td><Clip value={"110"} /></td><td><Clip value={"156"} /></td><td><Clip value={"6E"} /></td><td><Clip value={"01101110"} /></td><td><Clip value={"n"} /></td><td><Clip value={"&#110;"} /></td><td></td><td><Clip value={"Lowercase n"} /></td></tr>
      <tr><td><Clip value={"111"} /></td><td><Clip value={"157"} /></td><td><Clip value={"6F"} /></td><td><Clip value={"01101111"} /></td><td><Clip value={"o"} /></td><td><Clip value={"&#111;"} /></td><td></td><td><Clip value={"Lowercase o"} /></td></tr>
      <tr><td><Clip value={"112"} /></td><td><Clip value={"160"} /></td><td><Clip value={"70"} /></td><td><Clip value={"01110000"} /></td><td><Clip value={"p"} /></td><td><Clip value={"&#112;"} /></td><td></td><td><Clip value={"Lowercase p"} /></td></tr>
      <tr><td><Clip value={"113"} /></td><td><Clip value={"161"} /></td><td><Clip value={"71"} /></td><td><Clip value={"01110001"} /></td><td><Clip value={"q"} /></td><td><Clip value={"&#113;"} /></td><td></td><td><Clip value={"Lowercase q"} /></td></tr>
      <tr><td><Clip value={"114"} /></td><td><Clip value={"162"} /></td><td><Clip value={"72"} /></td><td><Clip value={"01110010"} /></td><td><Clip value={"r"} /></td><td><Clip value={"&#114;"} /></td><td></td><td><Clip value={"Lowercase r"} /></td></tr>
      <tr><td><Clip value={"115"} /></td><td><Clip value={"163"} /></td><td><Clip value={"73"} /></td><td><Clip value={"01110011"} /></td><td><Clip value={"s"} /></td><td><Clip value={"&#115;"} /></td><td></td><td><Clip value={"Lowercase s"} /></td></tr>
      <tr><td><Clip value={"116"} /></td><td><Clip value={"164"} /></td><td><Clip value={"74"} /></td><td><Clip value={"01110100"} /></td><td><Clip value={"t"} /></td><td><Clip value={"&#116;"} /></td><td></td><td><Clip value={"Lowercase t"} /></td></tr>
      <tr><td><Clip value={"117"} /></td><td><Clip value={"165"} /></td><td><Clip value={"75"} /></td><td><Clip value={"01110101"} /></td><td><Clip value={"u"} /></td><td><Clip value={"&#117;"} /></td><td></td><td><Clip value={"Lowercase u"} /></td></tr>
      <tr><td><Clip value={"118"} /></td><td><Clip value={"166"} /></td><td><Clip value={"76"} /></td><td><Clip value={"01110110"} /></td><td><Clip value={"v"} /></td><td><Clip value={"&#118;"} /></td><td></td><td><Clip value={"Lowercase v"} /></td></tr>
      <tr><td><Clip value={"119"} /></td><td><Clip value={"167"} /></td><td><Clip value={"77"} /></td><td><Clip value={"01110111"} /></td><td><Clip value={"w"} /></td><td><Clip value={"&#119;"} /></td><td></td><td><Clip value={"Lowercase w"} /></td></tr>
      <tr><td><Clip value={"120"} /></td><td><Clip value={"170"} /></td><td><Clip value={"78"} /></td><td><Clip value={"01111000"} /></td><td><Clip value={"x"} /></td><td><Clip value={"&#120;"} /></td><td></td><td><Clip value={"Lowercase x"} /></td></tr>
      <tr><td><Clip value={"121"} /></td><td><Clip value={"171"} /></td><td><Clip value={"79"} /></td><td><Clip value={"01111001"} /></td><td><Clip value={"y"} /></td><td><Clip value={"&#121;"} /></td><td></td><td><Clip value={"Lowercase y"} /></td></tr>
      <tr><td><Clip value={"122"} /></td><td><Clip value={"172"} /></td><td><Clip value={"7A"} /></td><td><Clip value={"01111010"} /></td><td><Clip value={"z"} /></td><td><Clip value={"&#122;"} /></td><td></td><td><Clip value={"Lowercase z"} /></td></tr>
      <tr><td><Clip value={"123"} /></td><td><Clip value={"173"} /></td><td><Clip value={"7B"} /></td><td><Clip value={"01111011"} /></td><td><Clip value={"&#123;"} /></td><td><Clip value={"&#123;"} /></td><td></td><td><Clip value={"Opening brace"} /></td></tr>
      <tr><td><Clip value={"124"} /></td><td><Clip value={"174"} /></td><td><Clip value={"7C"} /></td><td><Clip value={"01111100"} /></td><td><Clip value={"|"} /></td><td><Clip value={"&#124;"} /></td><td></td><td><Clip value={"Vertical bar"} /></td></tr>
      <tr><td><Clip value={"125"} /></td><td><Clip value={"175"} /></td><td><Clip value={"7D"} /></td><td><Clip value={"01111101"} /></td><td><Clip value={"&#125;"} /></td><td><Clip value={"&#125;"} /></td><td></td><td><Clip value={"Closing brace"} /></td></tr>
      <tr><td><Clip value={"126"} /></td><td><Clip value={"176"} /></td><td><Clip value={"7E"} /></td><td><Clip value={"01111110"} /></td><td><Clip value={"~"} /></td><td><Clip value={"&#126;"} /></td><td></td><td><Clip value={"Equivalency sign - tilde"} /></td></tr>
      <tr><td><Clip value={"127"} /></td><td><Clip value={"177"} /></td><td><Clip value={"7F"} /></td><td><Clip value={"01111111"} /></td><td><Clip value={""} /></td><td><Clip value={"&#127;"} /></td><td></td><td><Clip value={"Delete"} /></td></tr>
      <tr><td><Clip value={"128"} /></td><td><Clip value={"200"} /></td><td><Clip value={"80"} /></td><td><Clip value={"10000000"} /></td><td><Clip value={"€"} /></td><td><Clip value={"&#128;"} /></td><td><Clip value={"&euro;"} /></td><td><Clip value={"Euro sign"} /></td></tr>
      <tr><td><Clip value={"129"} /></td><td><Clip value={"201"} /></td><td><Clip value={"81"} /></td><td><Clip value={"10000001"} /></td><td></td><td></td><td></td><td></td></tr>
      <tr><td><Clip value={"130"} /></td><td><Clip value={"202"} /></td><td><Clip value={"82"} /></td><td><Clip value={"10000010"} /></td><td><Clip value={"‚"} /></td><td><Clip value={"&#130;"} /></td><td><Clip value={"&sbquo;"} /></td><td><Clip value={"Single low-9 quotation mark"} /></td></tr>
      <tr><td><Clip value={"131"} /></td><td><Clip value={"203"} /></td><td><Clip value={"83"} /></td><td><Clip value={"10000011"} /></td><td><Clip value={"ƒ"} /></td><td><Clip value={"&#131;"} /></td><td><Clip value={"&fnof;"} /></td><td><Clip value={"Latin small letter f with hook"} /></td></tr>
      <tr><td><Clip value={"132"} /></td><td><Clip value={"204"} /></td><td><Clip value={"84"} /></td><td><Clip value={"10000100"} /></td><td><Clip value={"„"} /></td><td><Clip value={"&#132;"} /></td><td><Clip value={"&bdquo;"} /></td><td><Clip value={"Double low-9 quotation mark"} /></td></tr>
      <tr><td><Clip value={"133"} /></td><td><Clip value={"205"} /></td><td><Clip value={"85"} /></td><td><Clip value={"10000101"} /></td><td><Clip value={"…"} /></td><td><Clip value={"&#133;"} /></td><td><Clip value={"&hellip;"} /></td><td><Clip value={"Horizontal ellipsis"} /></td></tr>
      <tr><td><Clip value={"134"} /></td><td><Clip value={"206"} /></td><td><Clip value={"86"} /></td><td><Clip value={"10000110"} /></td><td><Clip value={"†"} /></td><td><Clip value={"&#134;"} /></td><td><Clip value={"&dagger;"} /></td><td><Clip value={"Dagger"} /></td></tr>
      <tr><td><Clip value={"135"} /></td><td><Clip value={"207"} /></td><td><Clip value={"87"} /></td><td><Clip value={"10000111"} /></td><td><Clip value={"‡"} /></td><td><Clip value={"&#135;"} /></td><td><Clip value={"&Dagger;"} /></td><td><Clip value={"Double dagger"} /></td></tr>
      <tr><td><Clip value={"136"} /></td><td><Clip value={"210"} /></td><td><Clip value={"88"} /></td><td><Clip value={"10001000"} /></td><td><Clip value={"ˆ"} /></td><td><Clip value={"&#136;"} /></td><td><Clip value={"&circ;"} /></td><td><Clip value={"Modifier letter circumflex accent"} /></td></tr>
      <tr><td><Clip value={"137"} /></td><td><Clip value={"211"} /></td><td><Clip value={"89"} /></td><td><Clip value={"10001001"} /></td><td><Clip value={"‰"} /></td><td><Clip value={"&#137;"} /></td><td><Clip value={"&permil;"} /></td><td><Clip value={"Per mille sign"} /></td></tr>
      <tr><td><Clip value={"138"} /></td><td><Clip value={"212"} /></td><td><Clip value={"8A"} /></td><td><Clip value={"10001010"} /></td><td><Clip value={"Š"} /></td><td><Clip value={"&#138;"} /></td><td><Clip value={"&Scaron;"} /></td><td><Clip value={"Latin capital letter S with caron"} /></td></tr>
      <tr><td><Clip value={"139"} /></td><td><Clip value={"213"} /></td><td><Clip value={"8B"} /></td><td><Clip value={"10001011"} /></td><td><Clip value={"‹"} /></td><td><Clip value={"&#139;"} /></td><td><Clip value={"&lsaquo;"} /></td><td><Clip value={"Single left-pointing angle quotation"} /></td></tr>
      <tr><td><Clip value={"140"} /></td><td><Clip value={"214"} /></td><td><Clip value={"8C"} /></td><td><Clip value={"10001100"} /></td><td><Clip value={"Œ"} /></td><td><Clip value={"&#140;"} /></td><td><Clip value={"&OElig;"} /></td><td><Clip value={"Latin capital ligature OE"} /></td></tr>
      <tr><td><Clip value={"141"} /></td><td><Clip value={"215"} /></td><td><Clip value={"8D"} /></td><td><Clip value={"10001101"} /></td><td></td><td></td><td></td><td></td></tr>
      <tr><td><Clip value={"142"} /></td><td><Clip value={"216"} /></td><td><Clip value={"8E"} /></td><td><Clip value={"10001110"} /></td><td><Clip value={"Ž"} /></td><td><Clip value={"&#142;"} /></td><td></td><td><Clip value={"Latin captial letter Z with caron"} /></td></tr>
      <tr><td><Clip value={"143"} /></td><td><Clip value={"217"} /></td><td><Clip value={"8F"} /></td><td><Clip value={"10001111"} /></td><td></td><td></td><td></td><td></td></tr>
      <tr><td><Clip value={"144"} /></td><td><Clip value={"220"} /></td><td><Clip value={"90"} /></td><td><Clip value={"10010000"} /></td><td></td><td></td><td></td><td></td></tr>
      <tr><td><Clip value={"145"} /></td><td><Clip value={"221"} /></td><td><Clip value={"91"} /></td><td><Clip value={"10010001"} /></td><td><Clip value={"‘"} /></td><td><Clip value={"&#145;"} /></td><td><Clip value={"&lsquo;"} /></td><td><Clip value={"Left single quotation mark"} /></td></tr>
      <tr><td><Clip value={"146"} /></td><td><Clip value={"222"} /></td><td><Clip value={"92"} /></td><td><Clip value={"10010010"} /></td><td><Clip value={"’"} /></td><td><Clip value={"&#146;"} /></td><td><Clip value={"&rsquo;"} /></td><td><Clip value={"Right single quotation mark"} /></td></tr>
      <tr><td><Clip value={"147"} /></td><td><Clip value={"223"} /></td><td><Clip value={"93"} /></td><td><Clip value={"10010011"} /></td><td><Clip value={"“"} /></td><td><Clip value={"&#147;"} /></td><td><Clip value={"&ldquo;"} /></td><td><Clip value={"Left double quotation mark"} /></td></tr>
      <tr><td><Clip value={"148"} /></td><td><Clip value={"224"} /></td><td><Clip value={"94"} /></td><td><Clip value={"10010100"} /></td><td><Clip value={"”"} /></td><td><Clip value={"&#148;"} /></td><td><Clip value={"&rdquo;"} /></td><td><Clip value={"Right double quotation mark"} /></td></tr>
      <tr><td><Clip value={"149"} /></td><td><Clip value={"225"} /></td><td><Clip value={"95"} /></td><td><Clip value={"10010101"} /></td><td><Clip value={"•"} /></td><td><Clip value={"&#149;"} /></td><td><Clip value={"&bull;"} /></td><td><Clip value={"Bullet"} /></td></tr>
      <tr><td><Clip value={"150"} /></td><td><Clip value={"226"} /></td><td><Clip value={"96"} /></td><td><Clip value={"10010110"} /></td><td><Clip value={"–"} /></td><td><Clip value={"&#150;"} /></td><td><Clip value={"&ndash;"} /></td><td><Clip value={"En dash"} /></td></tr>
      <tr><td><Clip value={"151"} /></td><td><Clip value={"227"} /></td><td><Clip value={"97"} /></td><td><Clip value={"10010111"} /></td><td><Clip value={"—"} /></td><td><Clip value={"&#151;"} /></td><td><Clip value={"&mdash;"} /></td><td><Clip value={"Em dash"} /></td></tr>
      <tr><td><Clip value={"152"} /></td><td><Clip value={"230"} /></td><td><Clip value={"98"} /></td><td><Clip value={"10011000"} /></td><td><Clip value={"˜"} /></td><td><Clip value={"&#152;"} /></td><td><Clip value={"&tilde;"} /></td><td><Clip value={"Small tilde"} /></td></tr>
      <tr><td><Clip value={"153"} /></td><td><Clip value={"231"} /></td><td><Clip value={"99"} /></td><td><Clip value={"10011001"} /></td><td><Clip value={"™"} /></td><td><Clip value={"&#153;"} /></td><td><Clip value={"&trade;"} /></td><td><Clip value={"Trade mark sign"} /></td></tr>
      <tr><td><Clip value={"154"} /></td><td><Clip value={"232"} /></td><td><Clip value={"9A"} /></td><td><Clip value={"10011010"} /></td><td><Clip value={"š"} /></td><td><Clip value={"&#154;"} /></td><td><Clip value={"&scaron;"} /></td><td><Clip value={"Latin small letter S with caron"} /></td></tr>
      <tr><td><Clip value={"155"} /></td><td><Clip value={"233"} /></td><td><Clip value={"9B"} /></td><td><Clip value={"10011011"} /></td><td><Clip value={"›"} /></td><td><Clip value={"&#155;"} /></td><td><Clip value={"&rsaquo; "} /></td><td><Clip value={"Single right-pointing angle quotation mark"} /></td></tr>
      <tr><td><Clip value={"156"} /></td><td><Clip value={"234"} /></td><td><Clip value={"9C"} /></td><td><Clip value={"10011100"} /></td><td><Clip value={"œ"} /></td><td><Clip value={"&#156;"} /></td><td><Clip value={"&oelig;"} /></td><td><Clip value={"Latin small ligature oe"} /></td></tr>
      <tr><td><Clip value={"157"} /></td><td><Clip value={"235"} /></td><td><Clip value={"9D"} /></td><td><Clip value={"10011101"} /></td><td></td><td></td><td></td><td></td></tr>
      <tr><td><Clip value={"158"} /></td><td><Clip value={"236"} /></td><td><Clip value={"9E"} /></td><td><Clip value={"10011110"} /></td><td><Clip value={"ž"} /></td><td><Clip value={"&#158;"} /></td><td></td><td><Clip value={"Latin small letter z with caron"} /></td></tr>
      <tr><td><Clip value={"159"} /></td><td><Clip value={"237"} /></td><td><Clip value={"9F"} /></td><td><Clip value={"10011111"} /></td><td><Clip value={"Ÿ"} /></td><td><Clip value={"&#159;"} /></td><td><Clip value={"&Yuml;"} /></td><td><Clip value={"Latin capital letter Y with diaeresis"} /></td></tr>
      <tr><td><Clip value={"160"} /></td><td><Clip value={"240"} /></td><td><Clip value={"A0"} /></td><td><Clip value={"10100000"} /></td><td></td><td><Clip value={"&#160;"} /></td><td><Clip value={"&nbsp;"} /></td><td><Clip value={"Non-breaking space"} /></td></tr>
      <tr><td><Clip value={"161"} /></td><td><Clip value={"241"} /></td><td><Clip value={"A1"} /></td><td><Clip value={"10100001"} /></td><td><Clip value={"¡"} /></td><td><Clip value={"&#161;"} /></td><td><Clip value={"&iexcl;"} /></td><td><Clip value={"Inverted exclamation mark"} /></td></tr>
      <tr><td><Clip value={"162"} /></td><td><Clip value={"242"} /></td><td><Clip value={"A2"} /></td><td><Clip value={"10100010"} /></td><td><Clip value={"¢"} /></td><td><Clip value={"&#162;"} /></td><td><Clip value={"&cent;"} /></td><td><Clip value={"Cent sign"} /></td></tr>
      <tr><td><Clip value={"163"} /></td><td><Clip value={"243"} /></td><td><Clip value={"A3"} /></td><td><Clip value={"10100011"} /></td><td><Clip value={"£"} /></td><td><Clip value={"&#163;"} /></td><td><Clip value={"&pound;"} /></td><td><Clip value={"Pound sign"} /></td></tr>
      <tr><td><Clip value={"164"} /></td><td><Clip value={"244"} /></td><td><Clip value={"A4"} /></td><td><Clip value={"10100100"} /></td><td><Clip value={"¤"} /></td><td><Clip value={"&#164;"} /></td><td><Clip value={"&curren;"} /></td><td><Clip value={"Currency sign"} /></td></tr>
      <tr><td><Clip value={"165"} /></td><td><Clip value={"245"} /></td><td><Clip value={"A5"} /></td><td><Clip value={"10100101"} /></td><td><Clip value={"¥"} /></td><td><Clip value={"&#165;"} /></td><td><Clip value={"&yen;"} /></td><td><Clip value={"Yen sign"} /></td></tr>
      <tr><td><Clip value={"166"} /></td><td><Clip value={"246"} /></td><td><Clip value={"A6"} /></td><td><Clip value={"10100110"} /></td><td><Clip value={"¦"} /></td><td><Clip value={"&#166;"} /></td><td><Clip value={"&brvbar;"} /></td><td><Clip value={"Pipe, Broken vertical bar"} /></td></tr>
      <tr><td><Clip value={"167"} /></td><td><Clip value={"247"} /></td><td><Clip value={"A7"} /></td><td><Clip value={"10100111"} /></td><td><Clip value={"§"} /></td><td><Clip value={"&#167;"} /></td><td><Clip value={"&sect;"} /></td><td><Clip value={"Section sign"} /></td></tr>
      <tr><td><Clip value={"168"} /></td><td><Clip value={"250"} /></td><td><Clip value={"A8"} /></td><td><Clip value={"10101000"} /></td><td><Clip value={"¨"} /></td><td><Clip value={"&#168;"} /></td><td><Clip value={"&uml;"} /></td><td><Clip value={"Spacing diaeresis - umlaut"} /></td></tr>
      <tr><td><Clip value={"169"} /></td><td><Clip value={"251"} /></td><td><Clip value={"A9"} /></td><td><Clip value={"10101001"} /></td><td><Clip value={"©"} /></td><td><Clip value={"&#169;"} /></td><td><Clip value={"&copy;"} /></td><td><Clip value={"Copyright sign"} /></td></tr>
      <tr><td><Clip value={"170"} /></td><td><Clip value={"252"} /></td><td><Clip value={"AA"} /></td><td><Clip value={"10101010"} /></td><td><Clip value={"ª"} /></td><td><Clip value={"&#170;"} /></td><td><Clip value={"&ordf;"} /></td><td><Clip value={"Feminine ordinal indicator"} /></td></tr>
      <tr><td><Clip value={"171"} /></td><td><Clip value={"253"} /></td><td><Clip value={"AB"} /></td><td><Clip value={"10101011"} /></td><td><Clip value={"«"} /></td><td><Clip value={"&#171;"} /></td><td><Clip value={"&laquo;"} /></td><td><Clip value={"Left double angle quotes"} /></td></tr>
      <tr><td><Clip value={"172"} /></td><td><Clip value={"254"} /></td><td><Clip value={"AC"} /></td><td><Clip value={"10101100"} /></td><td><Clip value={"¬"} /></td><td><Clip value={"&#172;"} /></td><td><Clip value={"&not;"} /></td><td><Clip value={"Not sign"} /></td></tr>
      <tr><td><Clip value={"173"} /></td><td><Clip value={"255"} /></td><td><Clip value={"AD"} /></td><td><Clip value={"10101101"} /></td><td><Clip value={"­"} /></td><td><Clip value={"&#173;"} /></td><td><Clip value={"&shy;"} /></td><td><Clip value={"Soft hyphen"} /></td></tr>
      <tr><td><Clip value={"174"} /></td><td><Clip value={"256"} /></td><td><Clip value={"AE"} /></td><td><Clip value={"10101110"} /></td><td><Clip value={"®"} /></td><td><Clip value={"&#174;"} /></td><td><Clip value={"&reg;"} /></td><td><Clip value={"Registered trade mark sign"} /></td></tr>
      <tr><td><Clip value={"175"} /></td><td><Clip value={"257"} /></td><td><Clip value={"AF"} /></td><td><Clip value={"10101111"} /></td><td><Clip value={"¯"} /></td><td><Clip value={"&#175;"} /></td><td><Clip value={"&macr;"} /></td><td><Clip value={"Spacing macron - overline"} /></td></tr>
      <tr><td><Clip value={"176"} /></td><td><Clip value={"260"} /></td><td><Clip value={"B0"} /></td><td><Clip value={"10110000"} /></td><td><Clip value={"°"} /></td><td><Clip value={"&#176;"} /></td><td><Clip value={"&deg;"} /></td><td><Clip value={"Degree sign"} /></td></tr>
      <tr><td><Clip value={"177"} /></td><td><Clip value={"261"} /></td><td><Clip value={"B1"} /></td><td><Clip value={"10110001"} /></td><td><Clip value={"±"} /></td><td><Clip value={"&#177;"} /></td><td><Clip value={"&plusmn;"} /></td><td><Clip value={"Plus-or-minus sign"} /></td></tr>
      <tr><td><Clip value={"178"} /></td><td><Clip value={"262"} /></td><td><Clip value={"B2"} /></td><td><Clip value={"10110010"} /></td><td><Clip value={"²"} /></td><td><Clip value={"&#178;"} /></td><td><Clip value={"&sup2;"} /></td><td><Clip value={"Superscript two - squared"} /></td></tr>
      <tr><td><Clip value={"179"} /></td><td><Clip value={"263"} /></td><td><Clip value={"B3"} /></td><td><Clip value={"10110011"} /></td><td><Clip value={"³"} /></td><td><Clip value={"&#179;"} /></td><td><Clip value={"&sup3;"} /></td><td><Clip value={"Superscript three - cubed"} /></td></tr>
      <tr><td><Clip value={"180"} /></td><td><Clip value={"264"} /></td><td><Clip value={"B4"} /></td><td><Clip value={"10110100"} /></td><td><Clip value={"´"} /></td><td><Clip value={"&#180;"} /></td><td><Clip value={"&acute;"} /></td><td><Clip value={"Acute accent - spacing acute"} /></td></tr>
      <tr><td><Clip value={"181"} /></td><td><Clip value={"265"} /></td><td><Clip value={"B5"} /></td><td><Clip value={"10110101"} /></td><td><Clip value={"µ"} /></td><td><Clip value={"&#181;"} /></td><td><Clip value={"&micro;"} /></td><td><Clip value={"Micro sign"} /></td></tr>
      <tr><td><Clip value={"182"} /></td><td><Clip value={"266"} /></td><td><Clip value={"B6"} /></td><td><Clip value={"10110110"} /></td><td><Clip value={"¶"} /></td><td><Clip value={"&#182;"} /></td><td><Clip value={"&para;"} /></td><td><Clip value={"Pilcrow sign - paragraph sign"} /></td></tr>
      <tr><td><Clip value={"183"} /></td><td><Clip value={"267"} /></td><td><Clip value={"B7"} /></td><td><Clip value={"10110111"} /></td><td><Clip value={"·"} /></td><td><Clip value={"&#183;"} /></td><td><Clip value={"&middot;"} /></td><td><Clip value={"Middle dot - Georgian comma"} /></td></tr>
      <tr><td><Clip value={"184"} /></td><td><Clip value={"270"} /></td><td><Clip value={"B8"} /></td><td><Clip value={"10111000"} /></td><td><Clip value={"¸"} /></td><td><Clip value={"&#184;"} /></td><td><Clip value={"&cedil;"} /></td><td><Clip value={"Spacing cedilla"} /></td></tr>
      <tr><td><Clip value={"185"} /></td><td><Clip value={"271"} /></td><td><Clip value={"B9"} /></td><td><Clip value={"10111001"} /></td><td><Clip value={"¹"} /></td><td><Clip value={"&#185;"} /></td><td><Clip value={"&sup1;"} /></td><td><Clip value={"Superscript one"} /></td></tr>
      <tr><td><Clip value={"186"} /></td><td><Clip value={"272"} /></td><td><Clip value={"BA"} /></td><td><Clip value={"10111010"} /></td><td><Clip value={"º"} /></td><td><Clip value={"&#186;"} /></td><td><Clip value={"&ordm;"} /></td><td><Clip value={"Masculine ordinal indicator"} /></td></tr>
      <tr><td><Clip value={"187"} /></td><td><Clip value={"273"} /></td><td><Clip value={"BB"} /></td><td><Clip value={"10111011"} /></td><td><Clip value={"»"} /></td><td><Clip value={"&#187;"} /></td><td><Clip value={"&raquo;"} /></td><td><Clip value={"Right double angle quotes"} /></td></tr>
      <tr><td><Clip value={"188"} /></td><td><Clip value={"274"} /></td><td><Clip value={"BC"} /></td><td><Clip value={"10111100"} /></td><td><Clip value={"¼"} /></td><td><Clip value={"&#188;"} /></td><td><Clip value={"&frac14;"} /></td><td><Clip value={"Fraction one quarter"} /></td></tr>
      <tr><td><Clip value={"189"} /></td><td><Clip value={"275"} /></td><td><Clip value={"BD"} /></td><td><Clip value={"10111101"} /></td><td><Clip value={"½"} /></td><td><Clip value={"&#189;"} /></td><td><Clip value={"&frac12;"} /></td><td><Clip value={"Fraction one half"} /></td></tr>
      <tr><td><Clip value={"190"} /></td><td><Clip value={"276"} /></td><td><Clip value={"BE"} /></td><td><Clip value={"10111110"} /></td><td><Clip value={"¾"} /></td><td><Clip value={"&#190;"} /></td><td><Clip value={"&frac34;"} /></td><td><Clip value={"Fraction three quarters"} /></td></tr>
      <tr><td><Clip value={"191"} /></td><td><Clip value={"277"} /></td><td><Clip value={"BF"} /></td><td><Clip value={"10111111"} /></td><td><Clip value={"¿"} /></td><td><Clip value={"&#191;"} /></td><td><Clip value={"&iquest;"} /></td><td><Clip value={"Inverted question mark"} /></td></tr>
      <tr><td><Clip value={"192"} /></td><td><Clip value={"300"} /></td><td><Clip value={"C0"} /></td><td><Clip value={"11000000"} /></td><td><Clip value={"À"} /></td><td><Clip value={"&#192;"} /></td><td><Clip value={"&Agrave;"} /></td><td><Clip value={"Latin capital letter A with grave"} /></td></tr>
      <tr><td><Clip value={"193"} /></td><td><Clip value={"301"} /></td><td><Clip value={"C1"} /></td><td><Clip value={"11000001"} /></td><td><Clip value={"Á"} /></td><td><Clip value={"&#193;"} /></td><td><Clip value={"&Aacute;"} /></td><td><Clip value={"Latin capital letter A with acute"} /></td></tr>
      <tr><td><Clip value={"194"} /></td><td><Clip value={"302"} /></td><td><Clip value={"C2"} /></td><td><Clip value={"11000010"} /></td><td><Clip value={"Â"} /></td><td><Clip value={"&#194;"} /></td><td><Clip value={"&Acirc;"} /></td><td><Clip value={"Latin capital letter A with circumflex"} /></td></tr>
      <tr><td><Clip value={"195"} /></td><td><Clip value={"303"} /></td><td><Clip value={"C3"} /></td><td><Clip value={"11000011"} /></td><td><Clip value={"Ã"} /></td><td><Clip value={"&#195;"} /></td><td><Clip value={"&Atilde;"} /></td><td><Clip value={"Latin capital letter A with tilde"} /></td></tr>
      <tr><td><Clip value={"196"} /></td><td><Clip value={"304"} /></td><td><Clip value={"C4"} /></td><td><Clip value={"11000100"} /></td><td><Clip value={"Ä"} /></td><td><Clip value={"&#196;"} /></td><td><Clip value={"&Auml;"} /></td><td><Clip value={"Latin capital letter A with diaeresis"} /></td></tr>
      <tr><td><Clip value={"197"} /></td><td><Clip value={"305"} /></td><td><Clip value={"C5"} /></td><td><Clip value={"11000101"} /></td><td><Clip value={"Å"} /></td><td><Clip value={"&#197;"} /></td><td><Clip value={"&Aring;"} /></td><td><Clip value={"Latin capital letter A with ring above"} /></td></tr>
      <tr><td><Clip value={"198"} /></td><td><Clip value={"306"} /></td><td><Clip value={"C6"} /></td><td><Clip value={"11000110"} /></td><td><Clip value={"Æ"} /></td><td><Clip value={"&#198;"} /></td><td><Clip value={"&AElig;"} /></td><td><Clip value={"Latin capital letter AE"} /></td></tr>
      <tr><td><Clip value={"199"} /></td><td><Clip value={"307"} /></td><td><Clip value={"C7"} /></td><td><Clip value={"11000111"} /></td><td><Clip value={"Ç"} /></td><td><Clip value={"&#199;"} /></td><td><Clip value={"&Ccedil;"} /></td><td><Clip value={"Latin capital letter C with cedilla"} /></td></tr>
      <tr><td><Clip value={"200"} /></td><td><Clip value={"310"} /></td><td><Clip value={"C8"} /></td><td><Clip value={"11001000"} /></td><td><Clip value={"È"} /></td><td><Clip value={"&#200;"} /></td><td><Clip value={"&Egrave;"} /></td><td><Clip value={"Latin capital letter E with grave"} /></td></tr>
      <tr><td><Clip value={"201"} /></td><td><Clip value={"311"} /></td><td><Clip value={"C9"} /></td><td><Clip value={"11001001"} /></td><td><Clip value={"É"} /></td><td><Clip value={"&#201;"} /></td><td><Clip value={"&Eacute;"} /></td><td><Clip value={"Latin capital letter E with acute"} /></td></tr>
      <tr><td><Clip value={"202"} /></td><td><Clip value={"312"} /></td><td><Clip value={"CA"} /></td><td><Clip value={"11001010"} /></td><td><Clip value={"Ê"} /></td><td><Clip value={"&#202;"} /></td><td><Clip value={"&Ecirc;"} /></td><td><Clip value={"Latin capital letter E with circumflex"} /></td></tr>
      <tr><td><Clip value={"203"} /></td><td><Clip value={"313"} /></td><td><Clip value={"CB"} /></td><td><Clip value={"11001011"} /></td><td><Clip value={"Ë"} /></td><td><Clip value={"&#203;"} /></td><td><Clip value={"&Euml;"} /></td><td><Clip value={"Latin capital letter E with diaeresis"} /></td></tr>
      <tr><td><Clip value={"204"} /></td><td><Clip value={"314"} /></td><td><Clip value={"CC"} /></td><td><Clip value={"11001100"} /></td><td><Clip value={"Ì"} /></td><td><Clip value={"&#204;"} /></td><td><Clip value={"&Igrave;"} /></td><td><Clip value={"Latin capital letter I with grave"} /></td></tr>
      <tr><td><Clip value={"205"} /></td><td><Clip value={"315"} /></td><td><Clip value={"CD"} /></td><td><Clip value={"11001101"} /></td><td><Clip value={"Í"} /></td><td><Clip value={"&#205;"} /></td><td><Clip value={"&Iacute;"} /></td><td><Clip value={"Latin capital letter I with acute"} /></td></tr>
      <tr><td><Clip value={"206"} /></td><td><Clip value={"316"} /></td><td><Clip value={"CE"} /></td><td><Clip value={"11001110"} /></td><td><Clip value={"Î"} /></td><td><Clip value={"&#206;"} /></td><td><Clip value={"&Icirc;"} /></td><td><Clip value={"Latin capital letter I with circumflex"} /></td></tr>
      <tr><td><Clip value={"207"} /></td><td><Clip value={"317"} /></td><td><Clip value={"CF"} /></td><td><Clip value={"11001111"} /></td><td><Clip value={"Ï"} /></td><td><Clip value={"&#207;"} /></td><td><Clip value={"&Iuml;"} /></td><td><Clip value={"Latin capital letter I with diaeresis"} /></td></tr>
      <tr><td><Clip value={"208"} /></td><td><Clip value={"320"} /></td><td><Clip value={"D0"} /></td><td><Clip value={"11010000"} /></td><td><Clip value={"Ð"} /></td><td><Clip value={"&#208;"} /></td><td><Clip value={"&ETH;"} /></td><td><Clip value={"Latin capital letter ETH"} /></td></tr>
      <tr><td><Clip value={"209"} /></td><td><Clip value={"321"} /></td><td><Clip value={"D1"} /></td><td><Clip value={"11010001"} /></td><td><Clip value={"Ñ"} /></td><td><Clip value={"&#209;"} /></td><td><Clip value={"&Ntilde;"} /></td><td><Clip value={"Latin capital letter N with tilde"} /></td></tr>
      <tr><td><Clip value={"210"} /></td><td><Clip value={"322"} /></td><td><Clip value={"D2"} /></td><td><Clip value={"11010010"} /></td><td><Clip value={"Ò"} /></td><td><Clip value={"&#210;"} /></td><td><Clip value={"&Ograve;"} /></td><td><Clip value={"Latin capital letter O with grave"} /></td></tr>
      <tr><td><Clip value={"211"} /></td><td><Clip value={"323"} /></td><td><Clip value={"D3"} /></td><td><Clip value={"11010011"} /></td><td><Clip value={"Ó"} /></td><td><Clip value={"&#211;"} /></td><td><Clip value={"&Oacute;"} /></td><td><Clip value={"Latin capital letter O with acute"} /></td></tr>
      <tr><td><Clip value={"212"} /></td><td><Clip value={"324"} /></td><td><Clip value={"D4"} /></td><td><Clip value={"11010100"} /></td><td><Clip value={"Ô"} /></td><td><Clip value={"&#212;"} /></td><td><Clip value={"&Ocirc;"} /></td><td><Clip value={"Latin capital letter O with circumflex"} /></td></tr>
      <tr><td><Clip value={"213"} /></td><td><Clip value={"325"} /></td><td><Clip value={"D5"} /></td><td><Clip value={"11010101"} /></td><td><Clip value={"Õ"} /></td><td><Clip value={"&#213;"} /></td><td><Clip value={"&Otilde;"} /></td><td><Clip value={"Latin capital letter O with tilde"} /></td></tr>
      <tr><td><Clip value={"214"} /></td><td><Clip value={"326"} /></td><td><Clip value={"D6"} /></td><td><Clip value={"11010110"} /></td><td><Clip value={"Ö"} /></td><td><Clip value={"&#214;"} /></td><td><Clip value={"&Ouml;"} /></td><td><Clip value={"Latin capital letter O with diaeresis"} /></td></tr>
      <tr><td><Clip value={"215"} /></td><td><Clip value={"327"} /></td><td><Clip value={"D7"} /></td><td><Clip value={"11010111"} /></td><td><Clip value={"×"} /></td><td><Clip value={"&#215;"} /></td><td><Clip value={"&times;"} /></td><td><Clip value={"Multiplication sign"} /></td></tr>
      <tr><td><Clip value={"216"} /></td><td><Clip value={"330"} /></td><td><Clip value={"D8"} /></td><td><Clip value={"11011000"} /></td><td><Clip value={"Ø"} /></td><td><Clip value={"&#216;"} /></td><td><Clip value={"&Oslash;"} /></td><td><Clip value={"Latin capital letter O with slash"} /></td></tr>
      <tr><td><Clip value={"217"} /></td><td><Clip value={"331"} /></td><td><Clip value={"D9"} /></td><td><Clip value={"11011001"} /></td><td><Clip value={"Ù"} /></td><td><Clip value={"&#217;"} /></td><td><Clip value={"&Ugrave;"} /></td><td><Clip value={"Latin capital letter U with grave"} /></td></tr>
      <tr><td><Clip value={"218"} /></td><td><Clip value={"332"} /></td><td><Clip value={"DA"} /></td><td><Clip value={"11011010"} /></td><td><Clip value={"Ú"} /></td><td><Clip value={"&#218;"} /></td><td><Clip value={"&Uacute;"} /></td><td><Clip value={"Latin capital letter U with acute"} /></td></tr>
      <tr><td><Clip value={"219"} /></td><td><Clip value={"333"} /></td><td><Clip value={"DB"} /></td><td><Clip value={"11011011"} /></td><td><Clip value={"Û"} /></td><td><Clip value={"&#219;"} /></td><td><Clip value={"&Ucirc;"} /></td><td><Clip value={"Latin capital letter U with circumflex"} /></td></tr>
      <tr><td><Clip value={"220"} /></td><td><Clip value={"334"} /></td><td><Clip value={"DC"} /></td><td><Clip value={"11011100"} /></td><td><Clip value={"Ü"} /></td><td><Clip value={"&#220;"} /></td><td><Clip value={"&Uuml;"} /></td><td><Clip value={"Latin capital letter U with diaeresis"} /></td></tr>
      <tr><td><Clip value={"221"} /></td><td><Clip value={"335"} /></td><td><Clip value={"DD"} /></td><td><Clip value={"11011101"} /></td><td><Clip value={"Ý"} /></td><td><Clip value={"&#221;"} /></td><td><Clip value={"&Yacute;"} /></td><td><Clip value={"Latin capital letter Y with acute"} /></td></tr>
      <tr><td><Clip value={"222"} /></td><td><Clip value={"336"} /></td><td><Clip value={"DE"} /></td><td><Clip value={"11011110"} /></td><td><Clip value={"Þ"} /></td><td><Clip value={"&#222;"} /></td><td><Clip value={"&THORN;"} /></td><td><Clip value={"Latin capital letter THORN"} /></td></tr>
      <tr><td><Clip value={"223"} /></td><td><Clip value={"337"} /></td><td><Clip value={"DF"} /></td><td><Clip value={"11011111"} /></td><td><Clip value={"ß"} /></td><td><Clip value={"&#223;"} /></td><td><Clip value={"&szlig;"} /></td><td><Clip value={"Latin small letter sharp s - ess-zed"} /></td></tr>
      <tr><td><Clip value={"224"} /></td><td><Clip value={"340"} /></td><td><Clip value={"E0"} /></td><td><Clip value={"11100000"} /></td><td><Clip value={"à"} /></td><td><Clip value={"&#224;"} /></td><td><Clip value={"&agrave;"} /></td><td><Clip value={"Latin small letter a with grave"} /></td></tr>
      <tr><td><Clip value={"225"} /></td><td><Clip value={"341"} /></td><td><Clip value={"E1"} /></td><td><Clip value={"11100001"} /></td><td><Clip value={"á"} /></td><td><Clip value={"&#225;"} /></td><td><Clip value={"&aacute;"} /></td><td><Clip value={"Latin small letter a with acute"} /></td></tr>
      <tr><td><Clip value={"226"} /></td><td><Clip value={"342"} /></td><td><Clip value={"E2"} /></td><td><Clip value={"11100010"} /></td><td><Clip value={"â"} /></td><td><Clip value={"&#226;"} /></td><td><Clip value={"&acirc;"} /></td><td><Clip value={"Latin small letter a with circumflex"} /></td></tr>
      <tr><td><Clip value={"227"} /></td><td><Clip value={"343"} /></td><td><Clip value={"E3"} /></td><td><Clip value={"11100011"} /></td><td><Clip value={"ã"} /></td><td><Clip value={"&#227;"} /></td><td><Clip value={"&atilde;"} /></td><td><Clip value={"Latin small letter a with tilde"} /></td></tr>
      <tr><td><Clip value={"228"} /></td><td><Clip value={"344"} /></td><td><Clip value={"E4"} /></td><td><Clip value={"11100100"} /></td><td><Clip value={"ä"} /></td><td><Clip value={"&#228;"} /></td><td><Clip value={"&auml;"} /></td><td><Clip value={"Latin small letter a with diaeresis"} /></td></tr>
      <tr><td><Clip value={"229"} /></td><td><Clip value={"345"} /></td><td><Clip value={"E5"} /></td><td><Clip value={"11100101"} /></td><td><Clip value={"å"} /></td><td><Clip value={"&#229;"} /></td><td><Clip value={"&aring;"} /></td><td><Clip value={"Latin small letter a with ring above"} /></td></tr>
      <tr><td><Clip value={"230"} /></td><td><Clip value={"346"} /></td><td><Clip value={"E6"} /></td><td><Clip value={"11100110"} /></td><td><Clip value={"æ"} /></td><td><Clip value={"&#230;"} /></td><td><Clip value={"&aelig;"} /></td><td><Clip value={"Latin small letter ae"} /></td></tr>
      <tr><td><Clip value={"231"} /></td><td><Clip value={"347"} /></td><td><Clip value={"E7"} /></td><td><Clip value={"11100111"} /></td><td><Clip value={"ç"} /></td><td><Clip value={"&#231;"} /></td><td><Clip value={"&ccedil;"} /></td><td><Clip value={"Latin small letter c with cedilla"} /></td></tr>
      <tr><td><Clip value={"232"} /></td><td><Clip value={"350"} /></td><td><Clip value={"E8"} /></td><td><Clip value={"11101000"} /></td><td><Clip value={"è"} /></td><td><Clip value={"&#232;"} /></td><td><Clip value={"&egrave;"} /></td><td><Clip value={"Latin small letter e with grave"} /></td></tr>
      <tr><td><Clip value={"233"} /></td><td><Clip value={"351"} /></td><td><Clip value={"E9"} /></td><td><Clip value={"11101001"} /></td><td><Clip value={"é"} /></td><td><Clip value={"&#233;"} /></td><td><Clip value={"&eacute;"} /></td><td><Clip value={"Latin small letter e with acute"} /></td></tr>
      <tr><td><Clip value={"234"} /></td><td><Clip value={"352"} /></td><td><Clip value={"EA"} /></td><td><Clip value={"11101010"} /></td><td><Clip value={"ê"} /></td><td><Clip value={"&#234;"} /></td><td><Clip value={"&ecirc;"} /></td><td><Clip value={"Latin small letter e with circumflex"} /></td></tr>
      <tr><td><Clip value={"235"} /></td><td><Clip value={"353"} /></td><td><Clip value={"EB"} /></td><td><Clip value={"11101011"} /></td><td><Clip value={"ë"} /></td><td><Clip value={"&#235;"} /></td><td><Clip value={"&euml;"} /></td><td><Clip value={"Latin small letter e with diaeresis"} /></td></tr>
      <tr><td><Clip value={"236"} /></td><td><Clip value={"354"} /></td><td><Clip value={"EC"} /></td><td><Clip value={"11101100"} /></td><td><Clip value={"ì"} /></td><td><Clip value={"&#236;"} /></td><td><Clip value={"&igrave;"} /></td><td><Clip value={"Latin small letter i with grave"} /></td></tr>
      <tr><td><Clip value={"237"} /></td><td><Clip value={"355"} /></td><td><Clip value={"ED"} /></td><td><Clip value={"11101101"} /></td><td><Clip value={"í"} /></td><td><Clip value={"&#237;"} /></td><td><Clip value={"&iacute;"} /></td><td><Clip value={"Latin small letter i with acute"} /></td></tr>
      <tr><td><Clip value={"238"} /></td><td><Clip value={"356"} /></td><td><Clip value={"EE"} /></td><td><Clip value={"11101110"} /></td><td><Clip value={"î"} /></td><td><Clip value={"&#238;"} /></td><td><Clip value={"&icirc;"} /></td><td><Clip value={"Latin small letter i with circumflex"} /></td></tr>
      <tr><td><Clip value={"239"} /></td><td><Clip value={"357"} /></td><td><Clip value={"EF"} /></td><td><Clip value={"11101111"} /></td><td><Clip value={"ï"} /></td><td><Clip value={"&#239;"} /></td><td><Clip value={"&iuml;"} /></td><td><Clip value={"Latin small letter i with diaeresis"} /></td></tr>
      <tr><td><Clip value={"240"} /></td><td><Clip value={"360"} /></td><td><Clip value={"F0"} /></td><td><Clip value={"11110000"} /></td><td><Clip value={"ð"} /></td><td><Clip value={"&#240;"} /></td><td><Clip value={"&eth;"} /></td><td><Clip value={"Latin small letter eth"} /></td></tr>
      <tr><td><Clip value={"241"} /></td><td><Clip value={"361"} /></td><td><Clip value={"F1"} /></td><td><Clip value={"11110001"} /></td><td><Clip value={"ñ"} /></td><td><Clip value={"&#241;"} /></td><td><Clip value={"&ntilde;"} /></td><td><Clip value={"Latin small letter n with tilde"} /></td></tr>
      <tr><td><Clip value={"242"} /></td><td><Clip value={"362"} /></td><td><Clip value={"F2"} /></td><td><Clip value={"11110010"} /></td><td><Clip value={"ò"} /></td><td><Clip value={"&#242;"} /></td><td><Clip value={"&ograve;"} /></td><td><Clip value={"Latin small letter o with grave"} /></td></tr>
      <tr><td><Clip value={"243"} /></td><td><Clip value={"363"} /></td><td><Clip value={"F3"} /></td><td><Clip value={"11110011"} /></td><td><Clip value={"ó"} /></td><td><Clip value={"&#243;"} /></td><td><Clip value={"&oacute;"} /></td><td><Clip value={"Latin small letter o with acute"} /></td></tr>
      <tr><td><Clip value={"244"} /></td><td><Clip value={"364"} /></td><td><Clip value={"F4"} /></td><td><Clip value={"11110100"} /></td><td><Clip value={"ô"} /></td><td><Clip value={"&#244;"} /></td><td><Clip value={"&ocirc;"} /></td><td><Clip value={"Latin small letter o with circumflex"} /></td></tr>
      <tr><td><Clip value={"245"} /></td><td><Clip value={"365"} /></td><td><Clip value={"F5"} /></td><td><Clip value={"11110101"} /></td><td><Clip value={"õ"} /></td><td><Clip value={"&#245;"} /></td><td><Clip value={"&otilde;"} /></td><td><Clip value={"Latin small letter o with tilde"} /></td></tr>
      <tr><td><Clip value={"246"} /></td><td><Clip value={"366"} /></td><td><Clip value={"F6"} /></td><td><Clip value={"11110110"} /></td><td><Clip value={"ö"} /></td><td><Clip value={"&#246;"} /></td><td><Clip value={"&ouml;"} /></td><td><Clip value={"Latin small letter o with diaeresis"} /></td></tr>
      <tr><td><Clip value={"247"} /></td><td><Clip value={"367"} /></td><td><Clip value={"F7"} /></td><td><Clip value={"11110111"} /></td><td><Clip value={"÷"} /></td><td><Clip value={"&#247;"} /></td><td><Clip value={"&divide;"} /></td><td><Clip value={"Division sign"} /></td></tr>
      <tr><td><Clip value={"248"} /></td><td><Clip value={"370"} /></td><td><Clip value={"F8"} /></td><td><Clip value={"11111000"} /></td><td><Clip value={"ø"} /></td><td><Clip value={"&#248;"} /></td><td><Clip value={"&oslash;"} /></td><td><Clip value={"Latin small letter o with slash"} /></td></tr>
      <tr><td><Clip value={"249"} /></td><td><Clip value={"371"} /></td><td><Clip value={"F9"} /></td><td><Clip value={"11111001"} /></td><td><Clip value={"ù"} /></td><td><Clip value={"&#249;"} /></td><td><Clip value={"&ugrave;"} /></td><td><Clip value={"Latin small letter u with grave"} /></td></tr>
      <tr><td><Clip value={"250"} /></td><td><Clip value={"372"} /></td><td><Clip value={"FA"} /></td><td><Clip value={"11111010"} /></td><td><Clip value={"ú"} /></td><td><Clip value={"&#250;"} /></td><td><Clip value={"&uacute;"} /></td><td><Clip value={"Latin small letter u with acute"} /></td></tr>
      <tr><td><Clip value={"251"} /></td><td><Clip value={"373"} /></td><td><Clip value={"FB"} /></td><td><Clip value={"11111011"} /></td><td><Clip value={"û"} /></td><td><Clip value={"&#251;"} /></td><td><Clip value={"&ucirc;"} /></td><td><Clip value={"Latin small letter u with circumflex"} /></td></tr>
      <tr><td><Clip value={"252"} /></td><td><Clip value={"374"} /></td><td><Clip value={"FC"} /></td><td><Clip value={"11111100"} /></td><td><Clip value={"ü"} /></td><td><Clip value={"&#252;"} /></td><td><Clip value={"&uuml;"} /></td><td><Clip value={"Latin small letter u with diaeresis"} /></td></tr>
      <tr><td><Clip value={"253"} /></td><td><Clip value={"375"} /></td><td><Clip value={"FD"} /></td><td><Clip value={"11111101"} /></td><td><Clip value={"ý"} /></td><td><Clip value={"&#253;"} /></td><td><Clip value={"&yacute;"} /></td><td><Clip value={"Latin small letter y with acute"} /></td></tr>
      <tr><td><Clip value={"254"} /></td><td><Clip value={"376"} /></td><td><Clip value={"FE"} /></td><td><Clip value={"11111110"} /></td><td><Clip value={"þ"} /></td><td><Clip value={"&#254;"} /></td><td><Clip value={"&thorn;"} /></td><td><Clip value={"Latin small letter thorn"} /></td></tr>
      <tr><td><Clip value={"255"} /></td><td><Clip value={"377"} /></td><td><Clip value={"FF"} /></td><td><Clip value={"11111111"} /></td><td><Clip value={"ÿ"} /></td><td><Clip value={"&#255;"} /></td><td><Clip value={"&yuml;"} /></td><td><Clip value={"Latin small letter y with diaeresis"} /></td></tr>
    </tbody></table>
}

export const getHTMLSymbols = () => {
  return <table>
    <tbody><tr>
      <th><b>Name</b></th>
      <th><b>Symbol</b></th>
      <th><b>Unicode</b></th>
      <th><b>HEX Code</b></th>
      <th><b>HTML Code</b></th>
      <th><b>HTML Entity</b></th>
    </tr>
      <tr><td><Clip value={"Left Arrow"} /></td><td><Clip value={"←"} /></td><td><Clip value={"U+02190"} /></td><td><Clip value={"&#x2190;"} /></td><td><Clip value={"&#8592;"} /></td><td><Clip value={"&larr;"} /></td><td><Clip value={"\\2190"} /></td></tr>
      <tr><td><Clip value={"Up Arrow"} /></td><td><Clip value={"↑"} /></td><td><Clip value={"U+02191"} /></td><td><Clip value={"&#x2191;"} /></td><td><Clip value={"&#8593;"} /></td><td><Clip value={"&uarr;"} /></td><td><Clip value={"\\2191"} /></td></tr>
      <tr><td><Clip value={"Right Arrow"} /></td><td><Clip value={"→"} /></td><td><Clip value={"U+02192"} /></td><td><Clip value={"&#x2192;"} /></td><td><Clip value={"&#8594;"} /></td><td><Clip value={"&rarr;"} /></td><td><Clip value={"\\2192"} /></td></tr>
      <tr><td><Clip value={"Down Arrow"} /></td><td><Clip value={"↓"} /></td><td><Clip value={"U+02193"} /></td><td><Clip value={"&#x2193;"} /></td><td><Clip value={"&#8595;"} /></td><td><Clip value={"&darr;"} /></td><td><Clip value={"\\2193"} /></td></tr>
      <tr><td><Clip value={"North West Arrow"} /></td><td><Clip value={"↖"} /></td><td><Clip value={"U+02196"} /></td><td><Clip value={"&#x2196;"} /></td><td><Clip value={"&#8598;"} /></td><td><Clip value={"&nwarr;"} /></td><td><Clip value={"\\2196"} /></td></tr>
      <tr><td><Clip value={"North East Arrow"} /></td><td><Clip value={"↗"} /></td><td><Clip value={"U+02197"} /></td><td><Clip value={"&#x2197;"} /></td><td><Clip value={"&#8599;"} /></td><td><Clip value={"&nearr;"} /></td><td><Clip value={"\\2197"} /></td></tr>
      <tr><td><Clip value={"South East Arrow"} /></td><td><Clip value={"↘"} /></td><td><Clip value={"U+02198"} /></td><td><Clip value={"&#x2198;"} /></td><td><Clip value={"&#8600;"} /></td><td><Clip value={"&searr;"} /></td><td><Clip value={"\\2198"} /></td></tr>
      <tr><td><Clip value={"South West Arrow"} /></td><td><Clip value={"↙"} /></td><td><Clip value={"U+02199"} /></td><td><Clip value={"&#x2199;"} /></td><td><Clip value={"&#8601;"} /></td><td><Clip value={"&swarr;"} /></td><td><Clip value={"\\2199"} /></td></tr>
      <tr><td><Clip value={"Copyright Sign"} /></td><td><Clip value={"©"} /></td><td><Clip value={"U+000A9"} /></td><td><Clip value={"&#xa9;"} /></td><td><Clip value={"&#169;"} /></td><td><Clip value={"&copy;"} /></td><td><Clip value={"\\00A9"} /></td></tr>
      <tr><td><Clip value={"Registered Trade Mark Sign"} /></td><td><Clip value={"®"} /></td><td><Clip value={"U+000AE"} /></td><td><Clip value={"&#xae;"} /></td><td><Clip value={"&#174;"} /></td><td><Clip value={"&reg;"} /></td><td><Clip value={"\\00AE"} /></td></tr>
      <tr><td><Clip value={"Trade Mark Sign"} /></td><td><Clip value={"™"} /></td><td><Clip value={"U+02122"} /></td><td><Clip value={"&#x2122;"} /></td><td><Clip value={"&#8482;"} /></td><td><Clip value={"&trade;"} /></td><td><Clip value={"\\2122"} /></td></tr>
      <tr><td><Clip value={"At Symbol"} /></td><td><Clip value={"@"} /></td><td><Clip value={"U+00040"} /></td><td><Clip value={"&#x40;"} /></td><td><Clip value={"&#64;"} /></td><td><Clip value={"&commat;"} /></td><td><Clip value={"\\0040"} /></td></tr>
      <tr><td><Clip value={"Ampersand"} /></td><td><Clip value={"&"} /></td><td><Clip value={"U+00026"} /></td><td><Clip value={"&#x26;"} /></td><td><Clip value={"&#38;"} /></td><td><Clip value={"&amp;"} /></td><td><Clip value={"\\0026"} /></td></tr>
      <tr><td><Clip value={"Check Mark"} /></td><td><Clip value={"✓"} /></td><td><Clip value={"U+02713"} /></td><td><Clip value={"&#x2713;"} /></td><td><Clip value={"&#10003;"} /></td><td><Clip value={"&check;"} /></td><td><Clip value={"\\2713"} /></td></tr>
      <tr><td><Clip value={"Degree Celsius"} /></td><td><Clip value={"℃"} /></td><td><Clip value={"U+02103"} /></td><td><Clip value={"&#x2103;"} /></td><td><Clip value={"&#8451;"} /></td><td></td><td><Clip value={"\\2103"} /></td></tr>
      <tr><td><Clip value={"Degree Fahrenheit"} /></td><td><Clip value={"℉"} /></td><td><Clip value={"U+02109"} /></td><td><Clip value={"&#x2109;"} /></td><td><Clip value={"&#8457;"} /></td><td></td><td><Clip value={"\\2109"} /></td></tr>
      <tr><td><Clip value={"Dollar Sign"} /></td><td><Clip value={"$"} /></td><td><Clip value={"U+00024"} /></td><td><Clip value={"&#x24;"} /></td><td><Clip value={"&#36;"} /></td><td><Clip value={"&dollar;"} /></td><td><Clip value={"\\0024"} /></td></tr>
      <tr><td><Clip value={"Cent Sign"} /></td><td><Clip value={"¢"} /></td><td><Clip value={"U+000A2"} /></td><td><Clip value={"&#xa2;"} /></td><td><Clip value={"&#162;"} /></td><td><Clip value={"&cent;"} /></td><td><Clip value={"\\00A2"} /></td></tr>
      <tr><td><Clip value={"Pound Sign"} /></td><td><Clip value={"£"} /></td><td><Clip value={"U+000A3"} /></td><td><Clip value={"&#xa3;"} /></td><td><Clip value={"&#163;"} /></td><td><Clip value={"&pound;"} /></td><td><Clip value={"\\00A3"} /></td></tr>
      <tr><td><Clip value={"Euro Sign"} /></td><td><Clip value={"€"} /></td><td><Clip value={"U+020AC"} /></td><td><Clip value={"&#x20AC;"} /></td><td><Clip value={"&#8364;"} /></td><td><Clip value={"&euro;"} /></td><td><Clip value={"\\20AC"} /></td></tr>
      <tr><td><Clip value={"Yen Sign"} /></td><td><Clip value={"¥"} /></td><td><Clip value={"U+000A5"} /></td><td><Clip value={"&#xa5;"} /></td><td><Clip value={"&#165;"} /></td><td><Clip value={"&yen;"} /></td><td><Clip value={"\\00A5"} /></td></tr>
      <tr><td><Clip value={"Indian Rupee Sign"} /></td><td><Clip value={"₹"} /></td><td><Clip value={"U+020B9"} /></td><td><Clip value={"&#x20B9;"} /></td><td><Clip value={"&#8377;"} /></td><td></td><td><Clip value={"\\20B9"} /></td></tr>
      <tr><td><Clip value={"Ruble Sign"} /></td><td><Clip value={"₽"} /></td><td><Clip value={"U+020BD"} /></td><td><Clip value={"&#x20BD;"} /></td><td><Clip value={"&#8381;"} /></td><td></td><td><Clip value={"\\20BD"} /></td></tr>
      <tr><td><Clip value={"Yuan Character, in China"} /></td><td><Clip value={"元"} /></td><td><Clip value={"U+05143"} /></td><td><Clip value={"&#x5143;"} /></td><td><Clip value={"&#20803;"} /></td><td></td><td><Clip value={"\\5143"} /></td></tr>
      <tr><td><Clip value={"Plus Sign"} /></td><td><Clip value={"+"} /></td><td><Clip value={"U+0002B"} /></td><td><Clip value={"&#x2b;"} /></td><td><Clip value={"&#43;"} /></td><td><Clip value={"&plus;"} /></td><td><Clip value={"\\002B"} /></td></tr>
      <tr><td><Clip value={"Minus Sign"} /></td><td><Clip value={"−"} /></td><td><Clip value={"U+02212"} /></td><td><Clip value={"&#x2212;"} /></td><td><Clip value={"&#8722;"} /></td><td><Clip value={"&minus;"} /></td><td><Clip value={"\\2212"} /></td></tr>
      <tr><td><Clip value={"Multiplication Sign"} /></td><td><Clip value={"×"} /></td><td><Clip value={"U+000D7"} /></td><td><Clip value={"&#xd7;"} /></td><td><Clip value={"&#215;"} /></td><td><Clip value={"&times;"} /></td><td><Clip value={"\\00D7"} /></td></tr>
      <tr><td><Clip value={"Division Sign"} /></td><td><Clip value={"÷"} /></td><td><Clip value={"U+000F7"} /></td><td><Clip value={"&#xf7;"} /></td><td><Clip value={"&#247;"} /></td><td><Clip value={"&divide;"} /></td><td><Clip value={"\\00F7"} /></td></tr>
      <tr><td><Clip value={"Equal Sign"} /></td><td><Clip value={"="} /></td><td><Clip value={"U+0003D"} /></td><td><Clip value={"&#x3d;"} /></td><td><Clip value={"&#61;"} /></td><td><Clip value={"&equals;"} /></td><td><Clip value={"\\003D"} /></td></tr>
      <tr><td><Clip value={"Not Equal To Sign"} /></td><td><Clip value={"≠"} /></td><td><Clip value={"U+02260"} /></td><td><Clip value={"&#x2260;"} /></td><td><Clip value={"&#8800;"} /></td><td><Clip value={"&ne;"} /></td><td><Clip value={"\\2260"} /></td></tr>
      <tr><td><Clip value={"Less-Than Sign"} /></td><td><Clip value={"<"} /></td><td><Clip value={"U+0003C"} /></td><td><Clip value={"&#x3c;"} /></td><td><Clip value={"&#60;"} /></td><td><Clip value={"&lt;"} /></td><td><Clip value={"\\003C"} /></td><td><Clip value={""} /></td></tr>
      <tr><td><Clip value={"Greater-Than Sign"} /></td><td><Clip value={">"} /></td><td><Clip value={"U+0003E"} /></td><td><Clip value={"&#x3e;"} /></td><td><Clip value={"&#62;"} /></td><td><Clip value={"&gt;"} /></td><td><Clip value={"\\003E"} /></td></tr>
      <tr><td><Clip value={"Fraction One Quarter"} /></td><td><Clip value={"¼"} /></td><td><Clip value={"U+000BC"} /></td><td><Clip value={"&#xbc;"} /></td><td><Clip value={"&#188;"} /></td><td><Clip value={"&frac14;"} /></td><td><Clip value={"\\00BC"} /></td></tr>
      <tr><td><Clip value={"Fraction One Half"} /></td><td><Clip value={"½"} /></td><td><Clip value={"U+000BD"} /></td><td><Clip value={"&#xbd;"} /></td><td><Clip value={"&#189;"} /></td><td><Clip value={"&frac12;"} /></td><td><Clip value={"\\00BD"} /></td></tr>
      <tr><td><Clip value={"Fraction Three Quarters"} /></td><td><Clip value={"¾"} /></td><td><Clip value={"U+000BE"} /></td><td><Clip value={"&#xbe;"} /></td><td><Clip value={"&#190;"} /></td><td><Clip value={"&frac34;"} /></td><td><Clip value={"\\00BE"} /></td></tr>
      <tr><td><Clip value={"Vulgar Fraction One Third"} /></td><td><Clip value={"⅓"} /></td><td><Clip value={"U+02153"} /></td><td><Clip value={"&#x2153;"} /></td><td><Clip value={"&#8531;"} /></td><td><Clip value={"&frac13;"} /></td><td><Clip value={"\\2153"} /></td></tr>
      <tr><td><Clip value={"Vulgar Fraction Two Thirds"} /></td><td><Clip value={"⅔"} /></td><td><Clip value={"U+02154"} /></td><td><Clip value={"&#x2154;"} /></td><td><Clip value={"&#8532;"} /></td><td><Clip value={"&frac23;"} /></td><td><Clip value={"\\2154"} /></td></tr>
      <tr><td><Clip value={"Vulgar Fraction One Eighth"} /></td><td><Clip value={"⅛"} /></td><td><Clip value={"U+0215B"} /></td><td><Clip value={"&#x215B;"} /></td><td><Clip value={"&#8539;"} /></td><td><Clip value={"&frac18;"} /></td><td><Clip value={"\\215B"} /></td></tr>
      <tr><td><Clip value={"Vulgar Fraction Three Eighths"} /></td><td><Clip value={"⅜"} /></td><td><Clip value={"U+0215C"} /></td><td><Clip value={"&#x215C;"} /></td><td><Clip value={"&#8540;"} /></td><td><Clip value={"&frac38;"} /></td><td><Clip value={"\\215C"} /></td></tr>
      <tr><td><Clip value={"Vulgar Fraction Five Eighths"} /></td><td><Clip value={"⅝"} /></td><td><Clip value={"U+0215D"} /></td><td><Clip value={"&#x215D;"} /></td><td><Clip value={"&#8541;"} /></td><td><Clip value={"&frac58;"} /></td><td><Clip value={"\\215D"} /></td></tr>
      <tr><td><Clip value={"Exclamation Mark"} /></td><td><Clip value={"!"} /></td><td><Clip value={"U+00021"} /></td><td><Clip value={"&#x21;"} /></td><td><Clip value={"&#33;"} /></td><td><Clip value={"&excl;"} /></td><td><Clip value={"\\0021"} /></td></tr>
      <tr><td><Clip value={"Question Mark"} /></td><td><Clip value={"?"} /></td><td><Clip value={"U+0003F"} /></td><td><Clip value={"&#x3f;"} /></td><td><Clip value={"&#63;"} /></td><td><Clip value={"&quest;"} /></td><td><Clip value={"\\003F"} /></td></tr>
      <tr><td><Clip value={"En Dash"} /></td><td><Clip value={"–"} /></td><td><Clip value={"U+02013"} /></td><td><Clip value={"&#x2013;"} /></td><td><Clip value={"&#8211;"} /></td><td><Clip value={"&ndash;"} /></td><td><Clip value={"\\2013"} /></td></tr>
      <tr><td><Clip value={"Em Dash"} /></td><td><Clip value={"—"} /></td><td><Clip value={"U+02014"} /></td><td><Clip value={"&#x2014;"} /></td><td><Clip value={"&#8212;"} /></td><td><Clip value={"&mdash;"} /></td><td><Clip value={"\\2014"} /></td></tr>
      <tr><td><Clip value={"Single Left-Pointing Angle Quotation Mark"} /></td><td><Clip value={"‹"} /></td><td><Clip value={"U+02039"} /></td><td><Clip value={"&#x2039;"} /></td><td><Clip value={"&#8249;"} /></td><td><Clip value={"&lsaquo;"} /></td><td><Clip value={"\\2039"} /></td></tr>
      <tr><td><Clip value={"Single Right-Pointing Angle Quotation Mark"} /></td><td><Clip value={"›"} /></td><td><Clip value={"U+0203A"} /></td><td><Clip value={"&#x203A;"} /></td><td><Clip value={"&#8250;"} /></td><td><Clip value={"&rsaquo;"} /></td><td><Clip value={"\\203A"} /></td></tr>
      <tr><td><Clip value={"Double Left-Pointing Angle Quotation Mark"} /></td><td><Clip value={"«"} /></td><td><Clip value={"U+000AB"} /></td><td><Clip value={"&#xab;"} /></td><td><Clip value={"&#171;"} /></td><td><Clip value={"&laquo;"} /></td><td><Clip value={"\\00AB"} /></td></tr>
      <tr><td><Clip value={"Double Right-Pointing Angle Quotation Mark"} /></td><td><Clip value={"»"} /></td><td><Clip value={"U+000BB"} /></td><td><Clip value={"&#xbb;"} /></td><td><Clip value={"&#187;"} /></td><td><Clip value={"&raquo;"} /></td><td><Clip value={"\\00BB"} /></td></tr>
    </tbody>
  </table>
}
/*
Left Arrow	←	U+02190	&#x2190;	&#8592;	&larr;	\2190
Up Arrow	↑	U+02191	&#x2191;	&#8593;	&uarr;	\2191
Right Arrow	→	U+02192	&#x2192;	&#8594;	&rarr;	\2192
Down Arrow	↓	U+02193	&#x2193;	&#8595;	&darr;	\2193
North West Arrow	↖ U+02196	&#x2196;	&#8598;	&nwarr;	\2196
North East Arrow	↗ U+02197	&#x2197;	&#8599;	&nearr;	\2197
South East Arrow	 U+02198	&#x2198;	&#8600;	&searr;	\2198
South West Arrow	↙ U+02199	&#x2199;	&#8601;	&swarr;	\2199
Copyright Sign	©	U+000A9	&#xa9;	&#169;	&copy;	\00A9
Registered Trade Mark Sign	®	U+000AE	&#xae;	&#174;	&reg;	\00AE
Trade Mark Sign	™	U+02122	&#x2122; &#8482;	&trade;	\2122
At Symbol	@	U+00040	&#x40;	&#64;	&commat;	\0040
Ampersand	&	U+00026	&#x26;	&#38;	&amp;	\0026
Check Mark	✓	U+02713	&#x2713;	&#10003; &check;	\2713
Degree Celsius ℃	U+02103 &#x2103;	&#8451;	\2103
Degree Fahrenheit	℉	U+02109 &#x2109;	&#8457;	\2109
Dollar Sign	$	U+00024	&#x24;	&#36;	&dollar;	\0024
Cent Sign	¢	U+000A2	&#xa2;	&#162;	&cent;	\00A2
Pound Sign	£	U+000A3	&#xa3;	&#163;	&pound;	\00A3
Euro Sign €	U+020AC	&#x20AC;	&#8364;	&euro;	\20AC
Yen Sign	¥	U+000A5	&#xa5; &#165;	&yen;	\00A5
Indian Rupee Sign	₹	U+020B9	&#x20B9;	&#8377;	\20B9
Ruble Sign	₽	U+020BD	&#x20BD;	&#8381;	\20BD
Yuan Character, in China	元	U+05143	&#x5143;	&#20803;	\5143
Plus Sign	+	U+0002B	&#x2b;	&#43;	&plus;	\002B
Minus Sign	−	U+02212	&#x2212;	&#8722;	&minus;	\2212
Multiplication Sign	×	U+000D7	&#xd7;	&#215;	&times;	\00D7
Division Sign	÷	U+000F7	&#xf7;	&#247;	&divide;	\00F7
Equal Sign	=	U+0003D	&#x3d;	&#61;	&equals;	\003D
Not Equal To Sign	≠	U+02260	&#x2260;	&#8800;	&ne;	\2260
Less-Than Sign	<	U+0003C	&#x3c;	&#60;	&lt;	\003C	
Greater-Than Sign	>	U+0003E	&#x3e;	&#62;	&gt;	\003E
Fraction One Quarter	¼	U+000BC	&#xbc;	&#188;	&frac14;	\00BC
Fraction One Half	½	U+000BD	&#xbd;	&#189;	&frac12;	\00BD
Fraction Three Quarters	¾	U+000BE	&#xbe;	&#190;	&frac34;	\00BE
Vulgar Fraction One Third	⅓	U+02153	&#x2153;	&#8531;	&frac13;	\2153
Vulgar Fraction Two Thirds	⅔	U+02154	&#x2154;	&#8532;	&frac23;	\2154
Vulgar Fraction One Eighth	⅛	U+0215B	&#x215B;	&#8539;	&frac18;	\215B
Vulgar Fraction Three Eighths	⅜	U+0215C	&#x215C;	&#8540;	&frac38;	\215C
Vulgar Fraction Five Eighths	⅝	U+0215D	&#x215D;	&#8541;	&frac58;	\215D
Exclamation Mark	!	U+00021	&#x21;	&#33;	&excl;	\0021
Question Mark	?	U+0003F	&#x3f;	&#63;	&quest;	\003F
En Dash	–	U+02013	&#x2013;	&#8211;	&ndash;	\2013
Em Dash	—	U+02014	&#x2014;	&#8212;	&mdash;	\2014
Single Left-Pointing Angle Quotation Mark	‹	U+02039	&#x2039;	&#8249;	&lsaquo;	\2039
Single Right-Pointing Angle Quotation Mark	›	U+0203A	&#x203A;	&#8250;	&rsaquo;	\203A
Double Left-Pointing Angle Quotation Mark	«	U+000AB	&#xab;	&#171;	&laquo;	\00AB
Double Right-Pointing Angle Quotation Mark	»	U+000BB	&#xbb;	&#187;	&raquo;	\00BB
*/