import React, { useContext } from 'react';

import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { getKnownColors } from './data/Colors';
import { postColorData } from "../../comm/ApiComm";
import { ApiAnswer } from '../../models/ApiAnswer';
import { ColorData } from '../../models/ColorData';

import { HexColorPicker } from "react-colorful";
import { UserContext } from '../../contexts/UserContext';
import { useMode } from '../../components/layout/MainLayout';
import Clip from '../../components/Clip';

type Props = {};

const ColorsPage = (props: Props) => {
  const [colorData, setColorData] = React.useState<ApiAnswer<ColorData>>();
  const [color, setColor] = React.useState<string>('#fff');
  const [tab, setTab] = React.useState("color");

  const user = useContext(UserContext);
  const { notifyMessage } = useMode();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <>
      <h1>Color Utilities</h1>

      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="tabs">
            <Tab label={"Color"} value={"color"} />
            <Tab label={"Known Colors"} value={"colors"} />
          </TabList>
        </Box>

        <TabPanel value={"color"}>
          <div>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                const obj = Object.fromEntries(new FormData(event.currentTarget));
                postColorData(user, obj, setColorData);
              }}
            >
              <div>
                <Box>
                  <HexColorPicker color={color} onChange={setColor} />

                  <label>HEX:</label><TextField name="color" value={color} />
                  <label>RGB:</label><TextField name="color" />
                  <label>HSV:</label><TextField name="color" />
                  <label>HLS:</label><TextField name="color" />
                </Box>
              </div>
            </form>
          </div>
        </TabPanel>
        <TabPanel value={"colors"}>

          <TableContainer component={Paper} sx={{ maxWidth: 500 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow key={"header"}>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>HEX</TableCell>
                  <TableCell>RGB</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getKnownColors().map((clr) => {

                  return <>
                    <TableRow key={"row_" + clr.name}>
                      <TableCell sx={{ background: clr.hex }}></TableCell>
                      <TableCell><Clip value={clr.name} /></TableCell>
                      <TableCell><Clip value={clr.hex} /></TableCell>
                      <TableCell><Clip value={clr.r + "," + clr.g + "," + clr.b} /></TableCell>
                    </TableRow>
                  </>
                })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </TabContext>

    </>
  );
};

export default ColorsPage;