import React from 'react';

import { Divider, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { postTextData } from "../../comm/ApiComm";
import { ApiAnswer } from '../../models/ApiAnswer';
import { TextData } from '../../models/TextData';

import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useMode } from "../../components/layout/MainLayout";

type Props = {};

const TextPage = (props: Props) => {

  const [textData, setTextData] = React.useState<ApiAnswer<TextData>>();
  const user = useContext(UserContext);
  const { notifyMessage } = useMode();


  React.useEffect(() => {
    if (textData) {
      console.log(textData);
    }
  }, [textData]);

  return (
    <>
      <h1>Text Utilities</h1>
      <Divider />
      <p>
        You can find string operations, statistics and other useful features on this page. Just insert your text and press Analyze.
      </p>

      <div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            const obj = Object.fromEntries(new FormData(event.currentTarget));
            postTextData(user, obj, setTextData, notifyMessage);
          }}
        >
          <div>
            <Box component={Paper} >
              <TextField
                placeholder="Insert your text..."
                multiline
                rows={10}
                sx={{ width: "100%" }}
                name="string"
              />
            </Box>
          </div>
          <Button type="submit" variant="contained">Analyze</Button>
        </form>
      </div>

      <Stack spacing={2} direction={"row"}>
        <div>
          <h2>Statistics</h2>
          <TableContainer component={Paper} sx={{ maxWidth: 200 }}>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow key={"row_numOfLines"}>
                  <TableCell># lines</TableCell>
                  <TableCell>{textData?.data?.numOfLines}</TableCell>
                </TableRow>
                <TableRow key={"row_characters"}>
                  <TableCell># characters</TableCell>
                  <TableCell>{textData?.data?.numOfCharacters}</TableCell>
                </TableRow>
                <TableRow key={"row_whitespace"}>
                  <TableCell># whitespace</TableCell>
                  <TableCell>{textData?.data?.numOfWhitespaces}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div>
          <h2>Breakdown</h2>
          <TableContainer component={Paper} sx={{ maxWidth: 200 }}>
            <Table aria-label="simple table">
              <TableBody>
                {textData?.data?.characters.map((ch, i) => {
                  return <>
                    <TableRow key={ch}>
                      <TableCell>{ch}</TableCell>
                      <TableCell>{textData.data?.characters_counts[i]}</TableCell>
                    </TableRow>
                  </>
                })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Stack>

    </>

  );
};

export default TextPage;