import { createContext, useState } from "react";
import { postGoogleAuth } from '../comm/ApiComm'

import * as Realm from "realm-web";

// Creating a Realm App Instance
const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });

// Creating a user context to manage and access all the user related functions
// across different components and pages.
export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  //console.log('In auth provider');

  const [user, setUser] = useState(app.currentUser);

  // Function to log in user into our App Service app using their email & password
  const emailPasswordLogin = async (email, password) => {

    const credentials = Realm.Credentials.emailPassword(email, password);
    const authenticatedUser = await app.logIn(credentials);

    setUser(authenticatedUser);

    return authenticatedUser;
  };

  // Function to sign up user into our App Service app using their email & password
  const emailPasswordSignup = async (email, password) => {
    try {
      await app.emailPasswordAuth.registerUser({ email, password });
      // Since we are automatically confirming our users, we are going to log in
      // the user using the same credentials once the signup is complete.
      await emailPasswordLogin(email, password);

      // Refresh the user's local customData property
      await user.refreshCustomData();

      return user;

    } catch (error) {
      throw error;
    }
  };

  const loginWithGoogle = async (credential) => {
    var idToken = credential.id_token;

    const credentials = Realm.Credentials.google({ idToken });

    try {
      const user = await app.logIn(credentials);
      console.log("Logged in with Google as:", user.profile.email);

      setUser(user);

      return user;
    } catch (error) {
      console.error("Failed to log in with Google:", error);
      throw error;
    }
  }

  // Function to fetch the user (if the user is already logged in) from local storage
  const fetchUser = async () => {

    if (!user)
      return false;

    try {
      await app.currentUser.refreshCustomData();

      setUser(app.currentUser);

      return app.currentUser;
    } catch (error) {
      throw error;
    }
  }

  // Function to logout user from our App Services app
  const logOutUser = async () => {
    if (!app.currentUser)
      return false;

    try {
      console.log("logging out...");

      await app.currentUser.logOut();

      // Setting the user to null once loggedOut.
      setUser(null);

      return true;
    } catch (error) {
      throw error
    }
  }

  return <UserContext.Provider value={{ user, setUser, fetchUser, emailPasswordLogin, emailPasswordSignup, logOutUser, loginWithGoogle }}>
    {children}
  </UserContext.Provider>;
}