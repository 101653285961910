import React from 'react'

import { Button, Divider, TextField } from "@mui/material";
import { ChangeEvent, useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { useMode } from "../../components/layout/MainLayout";
import * as ApiComm from "../../comm/ApiComm";

import { gapi } from 'gapi-script';

const Signup = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { notifyMessage } = useMode();

    React.useEffect(() => {
        const initializeGapi = () => {
            console.log("init: " + process.env.REACT_APP_GOOGLE_CLIENT_ID);
              gapi.client.init({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, 
                scope: "profile email"
              });                      
        };
    
        gapi.load('client:auth2', initializeGapi);        
      }, []);


    // As explained in the Login page.
    const { emailPasswordSignup, loginWithGoogle } = useContext(UserContext);
    const [form, setForm] = useState({
        email: "",
        password: ""
    });

    // As explained in the Login page.
    const onFormInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };


    // As explained in the Login page.
    const redirectNow = () => {
        const redirectTo = location.search.replace("?redirectTo=", "");
        navigate(redirectTo ? redirectTo : "/");
    }

    // As explained in the Login page.
    const onSubmit = async () => {
        try {
            const user = await emailPasswordSignup(form.email, form.password);
            if (user) {
                redirectNow();
            }
        } catch (error : any) {
            notifyMessage(error.message, ApiComm.CALLBACK_CODE_ERROR);
        }
    };

    const onGoogleSignup = async () => {
        try {            
            const googleAuth = gapi.auth2.getAuthInstance();
            const googleUser = await googleAuth.signIn();
            const credential = googleUser.getAuthResponse();
            console.log("Google token: " + credential);

            const user = await loginWithGoogle(credential);
            if (user) {
                redirectNow();
            }
        } catch (error : any) {
           notifyMessage(error.message, ApiComm.CALLBACK_CODE_ERROR);
        }
    }

    return (
        <>
            <Container id="landingtext" sx={{ py: { xs: 4, sm: 8 } }}>

                <form style={{ display: "flex", flexDirection: "column", maxWidth: "300px", margin: "auto" }}>
                    <h1>Signup</h1>
                    <TextField
                        label="Email"
                        type="email"
                        variant="outlined"
                        name="email"
                        value={form.email}
                        onInput={onFormInputChange}
                        style={{ marginBottom: "1rem" }}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        name="password"
                        value={form.password}
                        onInput={onFormInputChange}
                        style={{ marginBottom: "1rem" }}
                    />
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        Signup
                    </Button>
                </form>

                
                <Divider sx={{ py: { xs: 1, sm: 2 } }} />

                <form style={{ paddingTop: "2em", display: "flex", flexDirection: "column", maxWidth: "300px", margin: "auto" }}>                
                    <Button variant="outlined" color="primary" onClick={onGoogleSignup}>
                        <img src='google_icon.svg' />Google        
                    </Button>
                </form>
                
                <Divider sx={{ py: { xs: 1, sm: 2 } }} />

                <form style={{ display: "flex", flexDirection: "column", maxWidth: "300px", margin: "auto" }}>
                    <p>Have an account already? <Link to="/signin">Login</Link></p>
                </form>

            </Container>
        </>
    )
}

export default Signup;