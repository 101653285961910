import React, { useContext } from 'react';

import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { postJsonData } from "../../comm/ApiComm";
import { ApiAnswer } from '../../models/ApiAnswer';
import { JsonData } from '../../models/JsonData';
import { UserContext } from '../../contexts/UserContext';
import { useMode } from '../../components/layout/MainLayout';

type Props = {};

const JsonPage = (props: Props) => {
  const [jsonData, setJsonData] = React.useState<ApiAnswer<JsonData>>();

  const user = useContext(UserContext);
  const { notifyMessage } = useMode();

  return (
    <>
      <h1>JSON Utilities</h1>
      <Divider />
      <div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            const obj = Object.fromEntries(new FormData(event.currentTarget));
            postJsonData(user, obj, setJsonData, notifyMessage);
          }}
        >
          <div>
            <Box component={Paper} >
              <Stack spacing={2} direction="row">
                <TextField
                  placeholder="Paste your JSON document..."
                  multiline
                  rows={20}
                  sx={{ width: "100%" }}
                  name="string"
                />
                <TextField
                  placeholder="Formatted..."
                  multiline
                  rows={20}
                  sx={{ width: "100%" }}
                  name="formatted"
                  disabled={!jsonData}
                />
              </Stack>
            </Box>
          </div>
          <Button type="submit" variant="contained">Submit</Button>
        </form>
      </div>

      <div>
        <h2>Statistics</h2>
        {"depth, num of nodes, unique names, ..."}
      </div>
    </>

  );
};

export default JsonPage;