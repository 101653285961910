import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';

import { useMode } from "./layout/MainLayout";

import * as ApiComm from "../comm/ApiComm";

type Props = {
    value: string | undefined,
    name?: string | undefined,
    editable?: boolean | undefined
};

const Clip = ({ name, value, editable }: Props) => {
    const [val, setValue] = React.useState<string>("");
    const [hovered, setHovered] = React.useState<boolean>(false);

    const { notifyMessage } = useMode();

    React.useEffect(() => {
        if (value) {
            setValue(value);
        }
    }, [value])

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const handleCopyClick = () => {
        if (val) {
            navigator.clipboard.writeText(val);

            notifyMessage("'" + val + "' copied to clipboard.", ApiComm.CALLBACK_CODE_SUCCESS);
        }
    }

    const handleTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }

    return (
        <>
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Stack spacing={2} direction="row">
                    {editable ?
                        <TextField name={name} value={val} onChange={handleTextChanged} />
                        :
                        <Typography
                            sx={{ cursor: hovered ? "pointer" : "default" }}
                            color={hovered ? "action.selected" : 'text.primary'}>
                            <div onClick={handleCopyClick}>{val}</div>
                        </Typography>
                    }
                </Stack>
            </div>
        </>
    )
}

export default Clip;