import React from 'react'

import { Button, Divider, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import Container from '@mui/material/Container';

import { useMode } from "../../components/layout/MainLayout";
import * as ApiComm from "../../comm/ApiComm";

import { gapi } from 'gapi-script';

type Props = {};

const Login = (props: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { notifyMessage } = useMode();
    
    // We are consuming our user-management context to
    // get & set the user details here
    const { user, fetchUser, emailPasswordLogin, loginWithGoogle } = useContext(UserContext);

    // We are using React's "useState" hook to keep track
    //  of the form values.
    const [form, setForm] = useState({
        email: "",
        password: ""
    });

    // This function will be called whenever the user edits the form.
    const onFormInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name: string = event.currentTarget.name;
        const value: string = event.currentTarget.value;

        setForm({ ...form, [name]: value });
    };

    // This function will redirect the user to the
    // appropriate page once the authentication is done.
    const redirectNow = () => {
        const redirectTo = location.search.replace("?redirectTo=", "");
        navigate(redirectTo ? redirectTo : "/");
    }

    // Once a user logs in to our app, we don’t want to ask them for their
    // credentials again every time the user refreshes or revisits our app, 
    // so we are checking if the user is already logged in and
    // if so we are redirecting the user to the home page.
    // Otherwise we will do nothing and let the user to login.
    const loadUser = async () => {
        if (!user) {
            const fetchedUser = await fetchUser();
            if (fetchedUser) {
                // Redirecting them once fetched.
                redirectNow();
            }
        }
    }

    // This useEffect will run only once when the component is mounted.
    // Hence this is helping us in verifying whether the user is already logged in
    // or not.
    useEffect(() => {
        loadUser(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // This function gets fired when the user clicks on the "Login" button.
    const onSubmit = async (event: React.SyntheticEvent) => {
        try {
            // Here we are passing user details to our emailPasswordLogin
            // function that we imported from our realm/authentication.js
            // to validate the user credentials and log in the user into our App.
            const user = await emailPasswordLogin(form.email, form.password);
            if (user) {
                redirectNow();
            }
        } catch (error: any) {
            if (error instanceof Error) {
                alert("Invalid username/password. Try again!");
            } else {
                alert(error);
            }

        }
    };

    React.useEffect(() => {
        const initializeGapi = () => {
            console.log("init: " + process.env.REACT_APP_GOOGLE_CLIENT_ID);
            gapi.client.init({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                scope: "profile email"
            });
        };

        gapi.load('client:auth2', initializeGapi);
    }, []);

    const onGoogleSignup = async () => {
        try {
            const googleAuth = gapi.auth2.getAuthInstance();
            const googleUser = await googleAuth.signIn();
            const credential = googleUser.getAuthResponse();
            console.log("Google token: " + credential);

            const user = await loginWithGoogle(credential);
            if (user) {
                redirectNow();
            }
        } catch (error: any) {
            notifyMessage(error.message, ApiComm.CALLBACK_CODE_ERROR);
        }
    }

    return (
        <>
            <Container id="landingtext" sx={{ py: { xs: 4, sm: 8 } }}>
                <form style={{ display: "flex", flexDirection: "column", maxWidth: "300px", margin: "auto" }}>
                    <h1>Login</h1>
                    <TextField
                        label="Email"
                        type="email"
                        variant="outlined"
                        name="email"
                        value={form.email}
                        onChange={onFormInputChange}
                        style={{ marginBottom: "1rem" }}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        name="password"
                        value={form.password}
                        onChange={onFormInputChange}
                        style={{ marginBottom: "1rem" }}
                    />
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        Login
                    </Button>
                    <p>Don't have an account? <Link to="/signup">Signup</Link></p>
                </form>

                <Divider sx={{ py: { xs: 1, sm: 2 } }} />

                <form style={{ paddingTop: "2em", display: "flex", flexDirection: "column", maxWidth: "300px", margin: "auto" }}>
                    <Button variant="outlined" color="primary" onClick={onGoogleSignup}>
                        <img src='google_icon.svg' />Google
                    </Button>
                </form>

                <Divider sx={{ py: { xs: 1, sm: 2 } }} />

                <form style={{ display: "flex", flexDirection: "column", maxWidth: "300px", margin: "auto" }}>
                    <p>Have an account already? <Link to="/signin">Login</Link></p>
                </form>
            </Container>
        </>)
}

export default Login;