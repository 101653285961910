import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

export default function LandingPageText() {

    return (
        <>
            <h1>DevelSlave</h1>
            <Typography
                variant="body1"
                color="text.secondary"
                sx={{ mb: { xs: 2, sm: 4 } }}
            >
                Welcome to <b>DevelSlave</b> — your all-in-one online repository for essential software development utilities accessible within one tab!
                Whether you’re debugging code, designing a UI, or optimizing network protocols, our suite of utilities offers everything you need to streamline and enhance your workflow.
            </Typography>
            <Typography
                variant="body1"
                color="text.secondary"
                sx={{ mb: { xs: 2, sm: 4 } }}
            >
                Our toolset grows with the needs of modern developers.
                Each utility is designed with simplicity, speed, and reliability in mind so you can focus on building amazing software.
                Start exploring DevelSlave today and discover how seamless development can be!
            </Typography>

            {/*
            <Container id="landingtext" sx={{ py: { xs: 8, sm: 16 } }}>
                <Grid container spacing={6}>
                    <Grid item  >
                        <div>
                            <Typography component="h2" variant="h4" color="text.primary">
                                DevelSlave
                            </Typography>

                        </div>
                    </Grid>
                </Grid>
            </Container>*/}
        </>
    );
}