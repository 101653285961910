import * as React from 'react';

import { useOutletContext } from "react-router-dom";

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import AppAppBar from './home/AppAppBar';
import LogoCollection from './home/LogoCollection';
import LandingPageText from './home/LandingPageText';
import Highlights from './home/Highlights';
import Pricing from './home/Pricing';
import Features from './home/Features';
import Testimonials from './home/Testimonials';
import FAQ from './home/FAQ';
import Footer from './home/Footer';
import { useMode } from "../components/layout/MainLayout";

const HomePage = () => {
  const { mode } = useMode();
  const { toggleColorMode } = useMode();

  return (
    <>
      {/*<AppAppBar mode={mode} toggleColorMode={toggleColorMode} />*/}
      <Box sx={{ bgcolor: 'background.default' }}>
        {/*<LogoCollection />*/}
        <LandingPageText />
        <Divider />
        {/*
        <Features />
        <Divider />
        <Testimonials />
        <Divider />
        <Highlights />
        <Divider />
        <Pricing />        
        <Divider />
        */}
        <FAQ />
      </Box>
      </>
  );
}

export default HomePage;