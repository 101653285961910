import { Box } from "@mui/material";
import { ReactNode, useEffect } from "react";

type Props = {
  state?: string,
  children: ReactNode;
};

const PageWrapper = (props: Props) => {
  /*const dispatch = useDispatch();

  useEffect(() => {
    if (props.state) {
      dispatch(setAppState(props.state));
    }
  }, [dispatch, props]);*/

  return (
    <Box sx={{ minHeight: "800px" }}>
      {props.children}
    </Box>
  );
};

export default PageWrapper;