import React from 'react';

type Props = {};

const PrivacyPolicy = (props: Props) => {
  return (
    <>
      <h1>Privacy Policy</h1>

      Last Updated: 31.10.2024

      <h3>1. Introduction</h3>
      <p>Welcome to DevelSlave! This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website develslave.com, which provides utilities for software development. By using our site, you consent to the practices described in this policy.</p>

      <h3>2. Information We Collect</h3>
      We collect only the necessary data to provide and improve our services:

      Account Information: When you create an account, we collect only basic information, such as your username and email address.
      API Access Logs: We store logs of your API access activities, including timestamps and usage data, to monitor and improve our services.

      <h3>3. How We Use Your Information</h3>
      We use your information for the following purposes:

      <ul>
        <li>Account Management: To create and manage your account on the platform.</li>
        <li>Service Improvement: To understand how our services are used and make improvements.</li>
        <li>Security: To monitor activity and detect, prevent, and address technical issues or potential security threats.</li>
      </ul>

      <h3>4. Disclosure of Your Information</h3>
      We do not sell, rent, or otherwise disclose your personal information to third parties, except in the following circumstances:

      Legal Requirements: If required by law or to respond to legal processes.
      Protecting Rights and Safety: When necessary to protect the rights, property, or safety of our users or the public.

      <h3>5. Data Security</h3>
      We implement appropriate technical and organizational measures to protect your information. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.

      <h3>6. Data Retention</h3>
      We retain your data only as long as necessary to fulfill the purposes outlined in this policy. API access logs are stored to help us improve the service and ensure security but may be deleted periodically.

      <h3>7. Your Rights</h3>
      Depending on your location, you may have the right to access, correct, or delete your data, or object to or restrict certain processing of your data. Please contact us if you would like to exercise these rights.

      <h3>8. Changes to This Privacy Policy</h3>
      We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated date.

      <h3>9. Contact Us</h3>
      If you have any questions or concerns regarding this Privacy Policy, please contact us at support@develslave.com.
    </>

  );
};

export default PrivacyPolicy;