import * as React from 'react';
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

const Signout = () => {
    const navigate = useNavigate();
    const user = useContext(UserContext);    
    //const { logOutUser } = useContext(UserContext);

    React.useEffect(() => {
        if(user) {
            user.logOutUser();
        }
        navigate('/');
    });

    return (<></>);
}

export default Signout;