import React from 'react';
import { useContext, useEffect } from "react";
import { BSON } from "realm-web";

import { Avatar, Button, Stack } from "@mui/material";
import { UserContext } from "../../contexts/UserContext";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { UserCustomData } from '../../models/UserCustomData';
import { postUserData, getUserOtherData } from '../../comm/ApiComm';

import { useMode } from "../../components/layout/MainLayout";
import { ApiAnswer } from '../../models/ApiAnswer';
import { UserOtherData } from '../../models/UserOtherData';
import CustomBarChart from '../../components/CustomBarChart';

type Props = {};

const UserPage = (props: Props) => {
  const { user, fetchUser } = useContext(UserContext);
  const [data, setUserData] = React.useState<UserCustomData | null>(null);
  const [userOtherData, setOtherUserData] = React.useState<ApiAnswer<UserOtherData>>();

  const [response, setResponse] = React.useState<ApiAnswer<UserCustomData> | undefined>();

  const { notifyMessage } = useMode();

  const refreshUser = async () => {
    fetchUser();
  }

  React.useEffect(() => {
    refreshUser();
  })

  React.useEffect(() => {
    if (user) {
      setUserData(user.customData);
      getUserOtherData(user, setOtherUserData, notifyMessage);
    }
  }, [user])

  React.useEffect(() => {
    console.log(userOtherData);
  }, [userOtherData])

  React.useEffect(() => {
    refreshUser();
  }, [response])

  return (
    <>
      <>
        <h1>User Page</h1>

        <TableContainer component={Paper} sx={{ maxWidth: "1300px" }}>
          <Table sx={{ minWidth: 200 }} aria-label="simple table">
            <TableBody>
              <TableRow key={"firstname"}>
                <TableCell rowSpan={6}><Avatar sx={{ height: "120px", width: "120px" }}></Avatar></TableCell>
                <TableCell>Firstname:</TableCell>
                <TableCell>{data?.firstname}</TableCell>
              </TableRow>
              <TableRow key={"lastname"}>
                <TableCell>Lastname:</TableCell>
                <TableCell>{data?.lastname}</TableCell>
              </TableRow>
              <TableRow key={"email"}>
                <TableCell>Email:</TableCell>
                <TableCell>{user?.profile?.email}</TableCell>
              </TableRow>
              <TableRow key={"api_key"}>
                <TableCell>API Key:</TableCell>
                <TableCell>{data?.api_key}</TableCell>
              </TableRow>
              <TableRow key={"tier"}>
                <TableCell>Tier:</TableCell>
                <TableCell>{userOtherData?.data?.licence?.tier}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>

      {
        user?.customData?.role == "admin" ?
          <>
            <h2>Admin</h2>
            {userOtherData?.data?.calls_stats?.keys && userOtherData?.data?.calls_stats?.values ?
              <>
                <Stack direction={"row"}>
                  <CustomBarChart label="API calls" labels={userOtherData?.data?.calls_stats.keys} data={userOtherData?.data?.calls_stats.values} />
                  <CustomBarChart label="API calls by month" labels={userOtherData?.data?.months_stats.keys} data={userOtherData?.data?.months_stats.values} />
                </Stack>
              </>
              :
              <></>
            }
          </>
          :
          <></>
      }
    </>
  );
};

export default UserPage;