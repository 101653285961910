import React from 'react';

type Props = {};

const TermsOfService = (props: Props) => {
  return (
    <>
      <h1>Terms of Use</h1>

      Last Updated: 31.10.2024

      <h3>1. Introduction</h3>
      Welcome to DevelSlave! By accessing or using our website www.develslave.com, including the utilities, tools, and APIs provided, you agree to comply with and be bound by these Terms of Use. If you do not agree to these terms, please refrain from using our services.

      <h3>2. Account Registration and Subscription</h3>
      To access certain features, including paid features, you may need to create an account and select a subscription plan. By creating an account and selecting a subscription, you agree to:

      Provide accurate and up-to-date information.
      Maintain the security of your account and be responsible for all activities under your account.
      Promptly notify us of any unauthorized access or use of your account.

      <h3>3. Paid Subscriptions</h3>
      Our site offers both free and paid subscription plans. By subscribing to a paid plan, you agree to the following additional terms:

      <ul>
        <li>Payment Terms: You agree to pay all fees associated with your selected subscription plan, as outlined during the sign-up process. All fees are non-refundable except as required by law or stated in this agreement.</li>
        <li>Automatic Renewal: Paid subscriptions renew automatically unless canceled before the renewal date. Renewal fees will be charged at the then-current rate.</li>
        <li>Changes to Subscription Plans: We reserve the right to adjust subscription fees or features at any time. If a change affects your current subscription, we will notify you in advance, and you may cancel your subscription before the changes take effect.</li>
      </ul>

      <h3>4. Use of Services</h3>
      Our services, including free and paid features, are provided solely for your personal or internal business use. You agree not to:

      Misuse or interfere with the normal operation of the website, services, or APIs.
      Attempt to gain unauthorized access to any part of the site or another user's data.
      Reverse engineer, decompile, or otherwise extract the underlying code or data.

      <h3>5. API Access</h3>
      If you are granted access to our APIs, you agree to the following additional terms:

      <ul>
        <li>Usage Limits: Adhere to any usage limits outlined in your account or subscription plan.</li>
        <li>Data Storage: We may monitor and store API access logs to improve our service and ensure compliance with usage limits.</li>
        <li>Non-commercial Use: Unless otherwise agreed upon, our API is provided for non-commercial use only. Please contact us if you wish to use it for commercial purposes.</li>
      </ul>

      <h3>6. Intellectual Property</h3>
      All content, software, and technology on the site are owned by [Your Website Name] or our licensors. You are granted a limited, non-exclusive, non-transferable license to access and use our services for your own personal or internal business purposes. You may not reproduce, distribute, or create derivative works without our explicit permission.

      <h3>7. Disclaimer of Warranties</h3>
      Our services are provided on an "AS IS" and "AS AVAILABLE" basis without warranties of any kind, whether express or implied. We do not guarantee that the services will be uninterrupted, secure, or error-free.

      <h3>8. Limitation of Liability</h3>
      To the fullest extent permitted by law, [Your Website Name] and its affiliates will not be liable for any damages arising out of or related to your use of the site or services. This includes, but is not limited to, direct, indirect, incidental, or consequential damages, even if we were advised of the possibility of such damages.

      <h3>9. Termination</h3>
      We reserve the right to suspend or terminate your account and access to our services at our sole discretion, without notice, if you violate these Terms of Use or engage in behavior that we deem inappropriate or harmful. If your paid account is terminated due to a breach of these terms, you will not be entitled to any refunds.

      <h3>10. Governing Law</h3>
      These Terms of Use are governed by the laws of [Your Jurisdiction]. Any disputes arising from your use of our services will be resolved under the jurisdiction of the courts in [Your Jurisdiction].

      <h3>11. Changes to Terms</h3>
      We reserve the right to modify these Terms of Use at any time. Any changes will be posted on this page with an updated date. Continued use of our services after any changes indicates your acceptance of the new terms.

      <h3>12. Contact Us</h3>
      If you have any questions about these Terms of Use, please contact us at support@develslave.com.

    </>

  );
};

export default TermsOfService;