import React from 'react';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Clip from '../../components/Clip';

import { getASCII, getHTMLSymbols } from './data/CodePages'

import { ApiAnswer } from '../../models/ApiAnswer';
import { EncodingData } from '../../models/EncodingData';

type Props = {};

const EncodingPage = (props: Props) => {
  const [encodingData, setEncodingData] = React.useState<ApiAnswer<EncodingData>>();
  const [tab, setTab] = React.useState("html");

  React.useEffect(() => {
    const fetchData = async () => {
      //const data = await fetch("https://devel-api.onrender.com/api/v1/ascii")
      //.then((res) => res.json())
      //.then((a) => setAsciiAnswer(a));
    }

    //fetchData();
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };



  return (
    <>
      <h1>Encoding</h1>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="tabs">
            <Tab label={"HTML Symbols"} value={"html"} />
            <Tab label={"ASCII"} value={"ascii"} />            
          </TabList>
        </Box>

        <TabPanel value={"ascii"}>
          {getASCII()}
          <h2>References</h2>
          <a href="https://en.wikipedia.org/wiki/ASCII" target="_blank">ASCII on Wikipedia</a>
        </TabPanel>
        <TabPanel value={"html"}>
          {getHTMLSymbols()}
        </TabPanel>
      </TabContext>

    </>

  );
};

export default EncodingPage;