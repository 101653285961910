import React from 'react';
import { useContext } from "react";
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';

import { postRandomizerData } from "../../comm/ApiComm";
import Clip from '../../components/Clip';

import { RandomizerData } from '../../models/RandomizerData';
import { ApiAnswer } from '../../models/ApiAnswer';
import { UserContext } from "../../contexts/UserContext";
import { useMode } from "../../components/layout/MainLayout";
import { CheckBox, Label } from '@mui/icons-material';
import { Checkbox, Divider, FormControlLabel, Stack, ToggleButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

type Props = {};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const RandomizerPage = (props: Props) => {
  const [randomizerData, setRandomizerData] = React.useState<ApiAnswer<RandomizerData>>();
  const [min, setMin] = React.useState<number>(0);
  const [max, setMax] = React.useState<number>(1);

  const [tab, setTab] = React.useState("string");

  const user = useContext(UserContext);
  const { notifyMessage } = useMode();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <>
      <h1>Randomizer Utilities</h1>

      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="tabs">
            <Tab label={"String Generator"} value={"string"} />
            <Tab label={"Number Generator"} value={"number"} />
          </TabList>
        </Box>

        <TabPanel value={"string"}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const obj = Object.fromEntries(new FormData(event.currentTarget));
              postRandomizerData(user, obj, setRandomizerData, notifyMessage);
            }}
          >
            <div>

              <Typography variant="h6">1. Length of the string</Typography>
              <Divider />
              <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
                <Grid size={4}>
                  <TextField type='number' name="string_length" defaultValue={32} sx={{ marginRight: "10px" }} />
                </Grid>
                <Grid size={8}>

                </Grid>
              </Grid>

              <Typography variant="h6">2. Possible characters</Typography>
              <Divider />
              <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
                <Grid size={3}>
                  <FormControlLabel control={<Checkbox defaultChecked />} name="string_lowercase" label="Lowercase [abcdefghijklmnopqrstuvwxyz]" />
                </Grid>
                <Grid size={3}>
                  <FormControlLabel control={<Checkbox defaultChecked />} name="string_uppercase" label="Uppercase [ABCDEFGHIJKLMNOPQRSTUVWXYZ]" />
                </Grid>
                <Grid size={3}>
                  <FormControlLabel control={<Checkbox defaultChecked />} name="string_numbers" label="Numbers [1234567890]" />
                </Grid>
                <Grid size={3}>
                  <FormControlLabel control={<Checkbox defaultChecked />} name="string_specialcharacters" label="Special characters [!@#$%^&*()_+:;'/.,[]=]" />
                </Grid>

                <Grid size={12} >
                  <FormControlLabel control={<TextField sx={{ marginRight: "1em" }} />} name="string_custom_domain" label="User specified" />
                </Grid>
              </Grid>

              <Typography variant="h6">3. Perform the action</Typography>
              <Divider />
              <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
                <Grid size={3} >
                  <Button type="submit" variant="contained">Generate</Button>
                </Grid>
                <Grid size={9}>
                </Grid>
              </Grid>
            </div>

          </form>

          <Typography variant="h6">4. Result</Typography>
          <Divider />
          <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
            <Grid size={12} >
              <Item>
                <Clip value={randomizerData?.data?.string_value} />
              </Item>
            </Grid>
          </Grid>


        </TabPanel>
        <TabPanel value={"number"}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const obj = Object.fromEntries(new FormData(event.currentTarget));
              postRandomizerData(user, obj, setRandomizerData, notifyMessage);
            }}
          >

            <Typography variant="h6">1. Input</Typography>
            <Divider />
            <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
              <Grid size={12}>
                <Button variant='outlined' onClick={e => { setMin(0); setMax(1) }} >[0, 1]</Button>
                <Button variant='outlined' onClick={e => { setMin(0); setMax(6) }} >[0, 6]</Button>
                <Button variant='outlined' onClick={e => { setMin(0); setMax(100) }} >[0, 100]</Button>
                <Button variant='outlined' onClick={e => { setMin(0); setMax(1000) }} >[1, 1000]</Button>
                <Button variant='outlined' onClick={e => { setMin(-1); setMax(1) }} >[-1, 1]</Button>
                <Button variant='outlined' onClick={e => { setMin(-100); setMax(100) }} >[-100, 100]</Button>
              </Grid>
              <Grid size={3}>
                <FormControlLabel control={<TextField value={min} onChange={e => setMin(Number(e.target.value))} sx={{ paddingRight: "1em" }} />} name="num_min" label="Min" />
              </Grid>
              <Grid size={3}>
                <FormControlLabel control={<TextField value={max} onChange={e => setMax(Number(e.target.value))} sx={{ paddingRight: "1em" }} />} name="num_max" label="Max" />
              </Grid>

              <Grid size={6} >
              </Grid>
            </Grid>

            <Typography variant="h6">2. Perform the action</Typography>
            <Divider />
            <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
              <Grid size={3} >
                <Button type="submit" variant="contained">Generate</Button>
              </Grid>
              <Grid size={9}>
              </Grid>
            </Grid>

          </form>

          <Typography variant="h6">3. Result</Typography>
          <Divider />
          <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
            <Grid size={12} >
              <Item>
                <Clip value={randomizerData?.data?.num_value?.toString()} />
              </Item>
            </Grid>
          </Grid>

        </TabPanel>
      </TabContext>

    </>

  );
};

export default RandomizerPage;