import { RouteType } from "./config";

import HomeIcon from '@mui/icons-material/Home';
import TagIcon from '@mui/icons-material/Tag';
import PaletteIcon from '@mui/icons-material/Palette';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import AbcIcon from '@mui/icons-material/Abc';
import MemoryIcon from '@mui/icons-material/Memory';
import CodeIcon from '@mui/icons-material/Code';
import DataObjectIcon from '@mui/icons-material/DataObject';
import LanguageIcon from '@mui/icons-material/Language';
import ShuffleIcon from '@mui/icons-material/Shuffle';

import HomePage from "../pages/HomePage";
import EncodingPage from "../pages/logic/EncodingPage";
import BinaryPage from "../pages/logic/BinaryPage";
import TextPage from "../pages/logic/TextPage";
import ColorsPage from "../pages/logic/ColorsPage";
import HashPage from "../pages/logic/HashPage";
import JsonPage from "../pages/logic/JsonPage";
import XmlPage from "../pages/logic/XmlPage";
import WebPage from "../pages/logic/WebPage";
import PrivacyPolicy from "../pages/home/PrivacyPolicy";
import TermsOfService from "../pages/home/TermsOfService";
import Login from "../pages/user/LoginPage";
import Signup from "../pages/user/SignupPage";
import Signout from "../pages/user/SignoutPage";
import ConfirmRegistration from "../pages/user/ConfirmRegistration";
import PasswordReset from "../pages/user/PasswordReset";
import RandomizerPage from "../pages/logic/RandomizerPage";


const appRoutes: RouteType[] = [
  {
    path: "/",
    index: true,
    element: <HomePage />,
    state: "home",
    sidebarProps: {
        displayText: "Home",
        icon: <HomeIcon />
      }
  },
  {
    path: "/encoding",
    element: <EncodingPage />,
    state: "encoding",        
    sidebarProps: {
        displayText: "Encoding",
        icon: <AbcIcon />
      }
  },
  {
    path: "/binary",
    element: <BinaryPage />,
    state: "binary",        
    sidebarProps: {
        displayText: "Binary",
        icon: <MemoryIcon />
      }
  },
  {
    path: "/text",
    element: <TextPage />,
    state: "text",        
    sidebarProps: {
        displayText: "Text",
        icon: <TextFormatIcon />
      }
  },
  {
    path: "/colors",
    element: <ColorsPage />,
    state: "colors",        
    sidebarProps: {
        displayText: "Colors",
        icon: <PaletteIcon />
      }
  },
  {
    path: "/hash",
    element: <HashPage />,
    state: "hash",        
    sidebarProps: {
        displayText: "Hash",
        icon: <TagIcon />
      }
  },
  {
    path: "/json",
    element: <JsonPage />,
    state: "json",        
    sidebarProps: {
        displayText: "Json",
        icon: <DataObjectIcon />
      }
  },
  {
    path: "/xml",
    element: <XmlPage />,
    state: "xml",        
    sidebarProps: {
        displayText: "XML",
        icon: <CodeIcon />
      }
  },
  {
    path: "/web",
    element: <WebPage />,
    state: "web",        
    sidebarProps: {
        displayText: "Web",
        icon: <LanguageIcon />
      }
  },
  {
    path: "/randomizer",
    element: <RandomizerPage />,
    state: "randomizer",        
    sidebarProps: {
        displayText: "Randomizer",
        icon: <ShuffleIcon />
      }
  },
  {
    path: "/privacyPolicy",
    element: <PrivacyPolicy />,
    state: "privacyPolicy"    
  },
  {
    path: "/termsOfService",
    element: <TermsOfService />,
    state: "termsOfService"    
  },
  {
    path: "/signin",
    element: <Login />,
    state: "signin"    
  }
  ,
  {
    path: "/signup",
    element: <Signup />,
    state: "signup"    
  },
  {
    path: "/signout",
    element: <Signout />,
    state: "signout"    
  },
  {
    path: "/confirmRegistration",
    element: <ConfirmRegistration />,
    state: "confirmRegistration"    
  },
  {
    path: "/resetPassword",
    element: <PasswordReset />,
    state: "resetPassword"    
  }
];   

const getRoutes = () => {
  
  let types : RouteType[] = [];

  appRoutes.forEach(function(route) {    
      types.push(route);    
  });  

  return types;
};


export default getRoutes;