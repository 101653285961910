export const getKnownColors = (): any[] => {
    var colors = [];
    colors.push({ name: "white", hex: "#FFFFFF", "r": 255, "g": 255, "b": 255 });
    colors.push({ name: "gray", hex: "#808080", "r": 128, "g": 128, "b": 128 });
    colors.push({ name: "silver", hex: "#C0C0C0", "r": 192, "g": 192, "b": 192 });
    colors.push({ name: "black", hex: "#000000", "r": 0, "g": 0, "b": 0 });
    colors.push({ name: "maroon", hex: "#800000", "r": 128, "g": 0, "b": 0 });
    colors.push({ name: "red", hex: "#FF0000", "r": 255, "g": 0, "b": 0 });
    colors.push({ name: "purple", hex: "#800080", "r": 128, "g": 0, "b": 128 });
    colors.push({ name: "fuchsia", hex: "#FF00FF", "r": 255, "g": 0, "b": 255 });
    colors.push({ name: "green", hex: "#008000", "r": 0, "g": 128, "b": 0 });
    colors.push({ name: "lime", hex: "#00FF00", "r": 0, "g": 255, "b": 0 });
    colors.push({ name: "olive", hex: "#808000", "r": 128, "g": 128, "b": 0 });
    colors.push({ name: "yellow", hex: "#FFFF00", "r": 255, "g": 255, "b": 0 });
    colors.push({ name: "navy", hex: "#000080", "r": 0, "g": 0, "b": 128 });
    colors.push({ name: "blue", hex: "#0000FF", "r": 0, "g": 0, "b": 255 });
    colors.push({ name: "teal", hex: "#008080", "r": 0, "g": 128, "b": 128 });
    colors.push({ name: "aqua", hex: "#00FFFF", "r": 0, "g": 255, "b": 255 });
    colors.push({ name: "orange", hex: "#FFA500", "r": 255, "g": 165, "b": 0 });

    // Red color names
    colors.push({ name: "indianred", hex: "#CD5C5C", "r": 205, "g": 92, "b": 92 });
    colors.push({ name: "lightcoral", hex: "#F08080", "r": 240, "g": 128, "b": 128 });
    colors.push({ name: "salmon", hex: "#FA8072", "r": 250, "g": 128, "b": 114 });
    colors.push({ name: "darksalmon", hex: "#E9967A", "r": 233, "g": 150, "b": 122 });
    colors.push({ name: "lightsalmon", hex: "#FFA07A", "r": 255, "g": 160, "b": 122 });
    colors.push({ name: "crimson", hex: "#DC143C", "r": 220, "g": 20, "b": 60 });
    colors.push({ name: "red", hex: "#FF0000", "r": 255, "g": 0, "b": 0 });
    colors.push({ name: "firebrick", hex: "#B22222", "r": 178, "g": 34, "b": 34 });
    colors.push({ name: "darkred", hex: "#8B0000", "r": 139, "g": 0, "b": 0 });
    // Pink color names
    colors.push({ name: "pink", hex: "#FFC0CB", "r": 255, "g": 192, "b": 203 });
    colors.push({ name: "lightpink", hex: "#FFB6C1", "r": 255, "g": 182, "b": 193 });
    colors.push({ name: "hotpink", hex: "#FF69B4", "r": 255, "g": 105, "b": 180 });
    colors.push({ name: "deeppink", hex: "#FF1493", "r": 255, "g": 20, "b": 147 });
    colors.push({ name: "mediumvioletred", hex: "#C71585", "r": 199, "g": 21, "b": 133 });
    colors.push({ name: "palevioletred", hex: "#DB7093", "r": 219, "g": 112, "b": 147 });
    // Orange color names
    colors.push({ name: "lightsalmon", hex: "#FFA07A", "r": 255, "g": 160, "b": 122 });
    colors.push({ name: "coral", hex: "#FF7F50", "r": 255, "g": 127, "b": 80 });
    colors.push({ name: "tomato", hex: "#FF6347", "r": 255, "g": 99, "b": 71 });
    colors.push({ name: "orangered", hex: "#FF4500", "r": 255, "g": 69, "b": 0 });
    colors.push({ name: "darkorange", hex: "#FF8C00", "r": 255, "g": 140, "b": 0 });
    colors.push({ name: "orange", hex: "#FFA500", "r": 255, "g": 165, "b": 0 });
    // Yellow color names
    colors.push({ name: "gold", hex: "#FFD700", "r": 255, "g": 215, "b": 0 });
    colors.push({ name: "yellow", hex: "#FFFF00", "r": 255, "g": 255, "b": 0 });
    colors.push({ name: "lightyellow", hex: "#FFFFE0", "r": 255, "g": 255, "b": 224 });
    colors.push({ name: "lemonchiffon", hex: "#FFFACD", "r": 255, "g": 250, "b": 205 });
    colors.push({ name: "lightgoldenrodyellow", hex: "#FAFAD2", "r": 250, "g": 250, "b": 210 });
    colors.push({ name: "papayawhip", hex: "#FFEFD5", "r": 255, "g": 239, "b": 213 });
    colors.push({ name: "moccasin", hex: "#FFE4B5", "r": 255, "g": 228, "b": 181 });
    colors.push({ name: "peachpuff", hex: "#FFDAB9", "r": 255, "g": 218, "b": 185 });
    colors.push({ name: "palegoldenrod", hex: "#EEE8AA", "r": 238, "g": 232, "b": 170 });
    colors.push({ name: "khaki", hex: "#F0E68C", "r": 240, "g": 230, "b": 140 });
    colors.push({ name: "darkkhaki", hex: "#BDB76B", "r": 189, "g": 183, "b": 107 });
    // Purple color names
    colors.push({ name: "lavender", hex: "#E6E6FA", "r": 230, "g": 230, "b": 250 });
    colors.push({ name: "thistle", hex: "#D8BFD8", "r": 216, "g": 191, "b": 216 });
    colors.push({ name: "plum", hex: "	#DDA0DD", "r": 221, "g": 160, "b": 221 });
    colors.push({ name: "violet", hex: "#EE82EE", "r": 238, "g": 130, "b": 238 });
    colors.push({ name: "orchid", hex: "#DA70D6", "r": 218, "g": 112, "b": 214 });
    colors.push({ name: "fuchsia", hex: "#FF00FF", "r": 255, "g": 0, "b": 255 });
    colors.push({ name: "magenta", hex: "#FF00FF", "r": 255, "g": 0, "b": 255 });
    colors.push({ name: "mediumorchid", hex: "#BA55D3", "r": 186, "g": 85, "b": 211 });
    colors.push({ name: "mediumpurple", hex: "#9370DB", "r": 147, "g": 112, "b": 219 });
    colors.push({ name: "blueviolet", hex: "#8A2BE2", "r": 138, "g": 43, "b": 226 });
    colors.push({ name: "darkviolet", hex: "#9400D3", "r": 148, "g": 0, "b": 211 });
    colors.push({ name: "darkorchid", hex: "#9932CC", "r": 153, "g": 50, "b": 204 });
    colors.push({ name: "darkmagenta", hex: "#8B008B", "r": 139, "g": 0, "b": 139 });
    colors.push({ name: "purple", hex: "#800080", "r": 128, "g": 0, "b": 128 });
    colors.push({ name: "indigo", hex: "#4B0082", "r": 75, "g": 0, "b": 130 });
    colors.push({ name: "slateblue", hex: "#6A5ACD", "r": 106, "g": 90, "b": 205 });
    colors.push({ name: "darkslateblue", hex: "#483D8B", "r": 72, "g": 61, "b": 139 });
    colors.push({ name: "mediumslateblue", hex: "#7B68EE", "r": 123, "g": 104, "b": 238 });
    // Green color names
    colors.push({ name: "greenyellow", hex: "#ADFF2F", "r": 173, "g": 255, "b": 47 });
    colors.push({ name: "chartreuse", hex: "#7FFF00", "r": 127, "g": 255, "b": 0 });
    colors.push({ name: "lawngreen", hex: "#7CFC00", "r": 124, "g": 252, "b": 0 });
    colors.push({ name: "lime", hex: "	#00FF00", "r": 0, "g": 255, "b": 0 });
    colors.push({ name: "limegreen", hex: "#32CD32", "r": 50, "g": 205, "b": 50 });
    colors.push({ name: "palegreen", hex: "#98FB98", "r": 152, "g": 251, "b": 152 });
    colors.push({ name: "lightgreen", hex: "#90EE90", "r": 144, "g": 238, "b": 144 });
    colors.push({ name: "mediumspringgreen", hex: "#00FA9A", "r": 0, "g": 250, "b": 154 });
    colors.push({ name: "springgreen", hex: "#00FF7F", "r": 0, "g": 255, "b": 127 });
    colors.push({ name: "mediumseagreen", hex: "#3CB371", "r": 60, "g": 179, "b": 113 });
    colors.push({ name: "seagreen", hex: "#2E8B57", "r": 46, "g": 139, "b": 87 });
    colors.push({ name: "forestgreen", hex: "#228B22", "r": 34, "g": 139, "b": 34 });
    colors.push({ name: "green", hex: "#008000", "r": 0, "g": 128, "b": 0 });
    colors.push({ name: "darkgreen", hex: "#006400", "r": 0, "g": 100, "b": 0 });
    colors.push({ name: "yellowgreen", hex: "#9ACD32", "r": 154, "g": 205, "b": 50 });
    colors.push({ name: "olivedrab", hex: "#6B8E23", "r": 107, "g": 142, "b": 35 });
    colors.push({ name: "olive", hex: "#808000", "r": 128, "g": 128, "b": 0 });
    colors.push({ name: "darkolivegreen", hex: "#556B2F", "r": 85, "g": 107, "b": 47 });
    colors.push({ name: "mediumaquamarine", hex: "#66CDAA", "r": 102, "g": 205, "b": 170 });
    colors.push({ name: "darkseagreen", hex: "	#8FBC8F", "r": 143, "g": 188, "b": 143 });
    colors.push({ name: "lightseagreen", hex: "#20B2AA", "r": 32, "g": 178, "b": 170 });
    colors.push({ name: "darkcyan", hex: "#008B8B", "r": 0, "g": 139, "b": 139 });
    colors.push({ name: "teal", hex: "#008080", "r": 0, "g": 128, "b": 128 });
    // Blue color names
    colors.push({ name: "aqua", hex: "#00FFFF", "r": 0, "g": 255, "b": 255 });
    colors.push({ name: "cyan", hex: "#00FFFF", "r": 0, "g": 255, "b": 255 });
    colors.push({ name: "lightcyan", hex: "#E0FFFF", "r": 224, "g": 255, "b": 255 });
    colors.push({ name: "paleturquoise", hex: "#AFEEEE", "r": 175, "g": 238, "b": 238 });
    colors.push({ name: "aquamarine", hex: "#7FFFD4", "r": 127, "g": 255, "b": 212 });
    colors.push({ name: "turquoise", hex: "#40E0D0", "r": 64, "g": 224, "b": 208 });
    colors.push({ name: "mediumturquoise", hex: "#48D1CC", "r": 72, "g": 209, "b": 204 });
    colors.push({ name: "darkturquoise", hex: "#00CED1", "r": 0, "g": 206, "b": 209 });
    colors.push({ name: "cadetblue", hex: "#5F9EA0", "r": 95, "g": 158, "b": 160 });
    colors.push({ name: "steelblue", hex: "#4682B4", "r": 70, "g": 130, "b": 180 });
    colors.push({ name: "lightsteelblue", hex: "#B0C4DE", "r": 176, "g": 196, "b": 222 });
    colors.push({ name: "powderblue", hex: "#B0E0E6", "r": 176, "g": 224, "b": 230 });
    colors.push({ name: "lightblue", hex: "#ADD8E6", "r": 173, "g": 216, "b": 230 });
    colors.push({ name: "skyblue", hex: "#87CEEB", "r": 135, "g": 206, "b": 235 });
    colors.push({ name: "lightskyblue", hex: "#87CEFA", "r": 135, "g": 206, "b": 250 });
    colors.push({ name: "deepskyblue", hex: "#00BFFF", "r": 0, "g": 191, "b": 255 });
    colors.push({ name: "dodgerblue", hex: "#1E90FF", "r": 30, "g": 144, "b": 255 });
    colors.push({ name: "cornflowerblue", hex: "#6495ED", "r": 100, "g": 149, "b": 237 });
    colors.push({ name: "mediumslateblue", hex: "#7B68EE", "r": 123, "g": 104, "b": 238 });
    colors.push({ name: "royalblue", hex: "#4169E1", "r": 65, "g": 105, "b": 225 });
    colors.push({ name: "blue", hex: "#0000FF", "r": 0, "g": 0, "b": 255 });
    colors.push({ name: "mediumblue", hex: "#0000CD", "r": 0, "g": 0, "b": 205 });
    colors.push({ name: "darkblue", hex: "#00008B", "r": 0, "g": 0, "b": 139 });
    colors.push({ name: "navy", hex: "#000080", "r": 0, "g": 0, "b": 128 });
    colors.push({ name: "midnightblue", hex: "#191970", "r": 25, "g": 25, "b": 112 });
    // Brown color names
    colors.push({ name: "cornsilk", hex: "#FFF8DC", "r": 255, "g": 248, "b": 220 });
    colors.push({ name: "blanchedalmond", hex: "#FFEBCD", "r": 255, "g": 235, "b": 205 });
    colors.push({ name: "bisque", hex: "#FFE4C4", "r": 255, "g": 228, "b": 196 });
    colors.push({ name: "navajowhite", hex: "#FFDEAD", "r": 255, "g": 222, "b": 173 });
    colors.push({ name: "wheat", hex: "#F5DEB3", "r": 245, "g": 222, "b": 179 });
    colors.push({ name: "burlywood", hex: "#DEB887", "r": 222, "g": 184, "b": 135 });
    colors.push({ name: "tan", hex: "#D2B48C", "r": 210, "g": 180, "b": 140 });
    colors.push({ name: "rosybrown", hex: "#BC8F8F", "r": 188, "g": 143, "b": 143 });
    colors.push({ name: "sandybrown", hex: "#F4A460", "r": 244, "g": 164, "b": 96 });
    colors.push({ name: "goldenrod", hex: "#DAA520", "r": 218, "g": 165, "b": 32 });
    colors.push({ name: "darkgoldenrod", hex: "#B8860B", "r": 184, "g": 134, "b": 11 });
    colors.push({ name: "peru", hex: "#CD853F", "r": 205, "g": 133, "b": 63 });
    colors.push({ name: "chocolate", hex: "#D2691E", "r": 210, "g": 105, "b": 30 });
    colors.push({ name: "saddlebrown", hex: "#8B4513", "r": 139, "g": 69, "b": 19 });
    colors.push({ name: "sienna", hex: "#A0522D", "r": 160, "g": 82, "b": 45 });
    colors.push({ name: "brown", hex: "#A52A2A", "r": 165, "g": 42, "b": 42 });
    colors.push({ name: "maroon", hex: "#800000", "r": 128, "g": 0, "b": 0 });
    // White color names
    colors.push({ name: "white", hex: "#FFFFFF", "r": 255, "g": 255, "b": 255 });
    colors.push({ name: "snow", hex: "#FFFAFA", "r": 255, "g": 250, "b": 250 });
    colors.push({ name: "honeydew", hex: "#F0FFF0", "r": 240, "g": 255, "b": 240 });
    colors.push({ name: "mintcream", hex: "#F5FFFA", "r": 245, "g": 255, "b": 250 });
    colors.push({ name: "azure", hex: "#F0FFFF", "r": 240, "g": 255, "b": 255 });
    colors.push({ name: "aliceblue", hex: "#F0F8FF", "r": 240, "g": 248, "b": 255 });
    colors.push({ name: "ghostwhite", hex: "#F8F8FF", "r": 248, "g": 248, "b": 255 });
    colors.push({ name: "whitesmoke", hex: "#F5F5F5", "r": 245, "g": 245, "b": 245 });
    colors.push({ name: "seashell", hex: "#FFF5EE", "r": 255, "g": 245, "b": 238 });
    colors.push({ name: "beige", hex: "#F5F5DC", "r": 245, "g": 245, "b": 220 });
    colors.push({ name: "oldlace", hex: "#FDF5E6", "r": 253, "g": 245, "b": 230 });
    colors.push({ name: "floralwhite", hex: "#FFFAF0", "r": 255, "g": 250, "b": 240 });
    colors.push({ name: "ivory", hex: "#FFFFF0", "r": 255, "g": 255, "b": 240 });
    colors.push({ name: "antiquewhite", hex: "#FAEBD7", "r": 250, "g": 235, "b": 215 });
    colors.push({ name: "linen", hex: "#FAF0E6", "r": 250, "g": 240, "b": 230 });
    colors.push({ name: "lavenderblush", hex: "#FFF0F5", "r": 255, "g": 240, "b": 245 });
    colors.push({ name: "mistyrose", hex: "#FFE4E1", "r": 255, "g": 228, "b": 225 });
    // Grey color names
    colors.push({ name: "gainsboro", hex: "#DCDCDC", "r": 220, "g": 220, "b": 220 });
    colors.push({ name: "lightgrey", hex: "#D3D3D3", "r": 211, "g": 211, "b": 211 });
    colors.push({ name: "silver", hex: "#C0C0C0", "r": 192, "g": 192, "b": 192 });
    colors.push({ name: "darkgray", hex: "#A9A9A9", "r": 169, "g": 169, "b": 169 });
    colors.push({ name: "gray", hex: "#808080", "r": 128, "g": 128, "b": 128 });
    colors.push({ name: "dimgray", hex: "#696969", "r": 105, "g": 105, "b": 105 });
    colors.push({ name: "lightslategray", hex: "#778899", "r": 119, "g": 136, "b": 153 });
    colors.push({ name: "slategray", hex: "#708090", "r": 112, "g": 128, "b": 144 });
    colors.push({ name: "darkslategray", hex: "#2F4F4F", "r": 47, "g": 79, "b": 79 });
    colors.push({ name: "black", hex: "#000000", "r": 0, "g": 0, "b": 0 });

    return colors;
}