import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import Button from '@mui/material/Button';
import { PaletteMode } from '@mui/material';
import { Avatar, Box, Drawer, List, Stack, Toolbar } from "@mui/material";

import sizeConfigs from "../../configs/sizeConfigs";
import getRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";

import { UserContext } from '../../contexts/UserContext';
import ToggleColorMode from '../../pages/home/ToggleColorMode';

interface AppAppBarProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

const Sidebar = ({ mode, toggleColorMode }: AppAppBarProps) => {
  var appRoutes = getRoutes();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const handleAvatarClick = () => {    
    navigate("/user");    
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: sizeConfigs.sidebar.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: sizeConfigs.sidebar.width,
          boxSizing: "border-box",
          borderRight: "1px solid text.primary",
          backgroundColor: 'primary.contrastText',
          color: 'color',
          zIndex: 0
        }
      }}
    >
      <List disablePadding>
        <Toolbar sx={{ marginBottom: "20px" }}>          
          <Stack
            sx={{ width: "100%", zIndex: 0, height: "10px" }}
            direction="row"
            justifyContent="center"
          >           
          <Avatar src="logo256.png" />
          </Stack>
        </Toolbar>
        {
          appRoutes.map((route, index) => (
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          ))}
      </List>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '50px' }}>
        {user ?
          <>
            <div onClick={handleAvatarClick}>
              <Avatar></Avatar>
            </div>
            <Button
              color="primary"
              variant="text"
              size="small"
              component="a"
              href="/#/signout/"              
            >
              Sign out
            </Button>
          </>
          :
          <>
            <Button
              color="primary"
              variant="text"
              size="small"
              component="a"
              href="/#/signin/"              
            >
              Sign in
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="small"
              component="a"
              href="/#/signup/"              
            >
              Sign up
            </Button>
          </>}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
      </Box>


    </Drawer>
  );
};

export default Sidebar;