import React from 'react';
import logo from './logo.svg';
import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";
import { routes } from "./routes";
import PrivateRoute from "./routes/PrivateRoute";

import './App.css';
import { UserProvider } from "./contexts/UserContext";
import Login from "./pages/user/LoginPage";
import Signup from "./pages/user/SignupPage";
import Signout from "./pages/user/SignoutPage";
import PasswordReset from './pages/user/PasswordReset';
import ConfirmRegistration from './pages/user/ConfirmRegistration';
import UserPage from './pages/user/UserPage';
import PrivacyPolicy from './pages/home/PrivacyPolicy';
import TermsOfService from './pages/home/TermsOfService';

function App() {
  React.useEffect(() => {
    // Load the Paddle script dynamically
    const script = document.createElement('script');
    script.src = 'https://cdn.paddle.com/paddle/paddle.js';
    script.async = true;
    script.onload = () => {
      if (window.Paddle) {
        window.Paddle.Setup({ vendor: 24446 }); // , sandbox: true }); // Replace with your Vendor ID
      }
    };
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <HashRouter>
      <UserProvider>
        <Routes>
          {/*
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsOfService" element={<TermsOfService />} />
        <Route path="/signin" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signout" element={<Signout />} />
        <Route path="/confirmRegistration" element={<ConfirmRegistration />} />   
        */}
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/user" element={<UserPage />} />
            {routes}
          </Route>
        </Routes>
      </UserProvider>
    </HashRouter>
  );
}

export default App;
